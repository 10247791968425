.my-quote {
    border-radius: 12px 0px 12px 12px;
    background-color: var(--gray);
    min-width: 100%;
    border: solid 1px var(--gray-200);

    .carousel-item {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        border-radius: 12px 0px 0px 12px;
    }
}

.my-quote .course-title {
    padding-top: 0;
    // cursor: url('../images/cursor-selector.png'), auto;
}

.my-quote .program-label {
    padding: 5px 10px 5px 10px;
    font-size: 10px;
    font-weight: 500;
}

.my-quote-tabs {
    display: flex;
    justify-content: end;
}

.my-quote-tab {
    border-radius: 6px 6px 0px 0px;
    border-style: solid;
    border-width: 1px 1px 0px 1px;
    border-color: var(--gray-200);
    color: var(--gray-200);
    padding: 6px 15px 6px 15px;
    font-size: 12px;
    font-weight: 600;
}

.my-quote-tab.active {
    color: var(--black);
}

.my-quote-direction{
    display: inline-flex;
    justify-content: space-between;
}
@primary-color: #2460B7;@link-color: #2460B7;@border-radius-base: 10px;@box-shadow-base: 0 9px 28px 8px rgba(0, 0, 0, 0.05);