.aplication__input,
.aplication__input-select,
.aplication__input-multiselect {
    padding: 0.625rem;
    font-size: 0.75rem;
    background-color: transparent;
}

.aplication__input-date {
    width: 100%;
    padding: 0.5rem;
    font-size: 0.75rem;
    border-radius: 10px;
}

.aplication__input.ant-input[disabled],
.aplication__input.ant-select-disabled {
    background-color: #DFDFDF !important;
    font-weight: 500;
    color: #000;
    border: 1px solid #BFBFBF;
}

.aplication__input-date.ant-picker-disabled {
    background-color: #DFDFDF;
    font-weight: 500;
    border: 1px solid #BFBFBF;
    & input {
        color: #000;
    }
}

.aplication__input-select.ant-select-disabled {
    & > div {
        background-color: #DFDFDF !important;
        border: 1px solid #BFBFBF;
        color: #000;
    }
}

.aplication__input-multiselect.ant-select-disabled {
    background-color: #DFDFDF !important;
    font-weight: 500;
    border: 1px solid #BFBFBF;
    & .ant-select-selection-item-content {
        color: #000;
    }
    & > div {
        background-color: #DFDFDF !important;
        border: 1px solid #BFBFBF;
        color: #000;
    }
}