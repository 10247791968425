/**RESPPONSIVE STYLES FOR
    BIG SCREENS width 1440 and up (1500w, 1700w, 2500w)
    LAPTOPS max-width w1280
    TABLETS max-widhtw 960 / max-width 1024
    HORIZONTAL PHONE w768, w690, w600
    BIG PHONE max-widht w568
    MEDIUM PHONE max-width w480, w425
    PHONE max-width w350, w320
**/

@import "vars.less";
@import "buttons-theme.less";

//BIG SCREENS
@media (min-width: 2500px) {
  .layout-maxWidth {
    width: 1800px;
    background-color: #f9f9f9;
    display: grid;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 12px 12px 0px;
  }

  .bg-bScreen {
    background-color: #eeeeee;
    display: grid;
    justify-content: center;
  }

  .space-content {
    width: 1650px;
    background-color: #f9f9f9;
    display: grid;
    justify-content: center;
  }

  .layout-home {
    width: 1500px;
  }

  //Buttons category
  .byp-btn-category.ant-btn-background-ghost > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    font-size: 14px;
    font-weight: 600;
  }

  //Subtitle
  .subtitle__big-home {
    font-size: 18px;
  }

  .margin-bloglist {
    margin-left: 8rem;
    margin-right: 8rem;
  }
}

@media (min-width: 1700px) {
  .layout-maxWidth {
    width: 1800px;
    background-color: #f9f9f9;
    display: grid;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 12px 12px 0px;
  }

  .bg-bScreen {
    background-color: #eeeeee;
    display: grid;
    justify-content: center;
  }

  .space-content {
    width: 1650px;
    background-color: #f9f9f9;
    display: grid;
    justify-content: center;
  }

  .layout-home {
    width: 1500px;
  }

  //Buttons category
  .byp-btn-category.ant-btn-background-ghost > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    font-size: 14px;
    font-weight: 600;
  }

  //Subtitle
  .subtitle__big-home {
    font-size: 18px;
  }
}

@media (min-width: 1500px) {
  //Body section
  .layout-maxWidth {
    width: 1800px;
    background-color: #f9f9f9;
    display: grid;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 12px 12px 0px;
  }

  .bg-bScreen {
    background-color: #eeeeee;
    display: grid;
    justify-content: center;
  }

  .space-content {
    width: 1650px;
    background-color: #f9f9f9;
    display: grid;
    justify-content: center;
  }

  .layout-home {
    width: 1500px;
  }

  //Buttons category
  .byp-btn-category.ant-btn-background-ghost > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    font-size: 14px;
    font-weight: 600;
  }

  //Subtitle
  .subtitle__big-home {
    font-size: 18px;
  }
}

//LAPTOPS
@media (max-width: 1440px) and (min-width: 769px) {
  .layout-maxWidth {
    width: 100%;
    display: grid;
    justify-content: center;
  }

  .space-content {
    width: 100%;
    display: grid;
    justify-content: center;
  }

  .big__form-header {
    align-self: center;
    width: 95vw;
    margin-top: 10px;
  }

  #btn-download {
    margin-right: 5px !important;
    display: block;
  }

  #btn-download-2 {
    margin-right: 5px !important;
    display: none;
  }
}

@media (max-width: 1440px) {
  .big-home__info {
    background-size: 200% !important;
  }
}

//LAPTOPS
@media (max-width: 1280px) {
  .big-home__info {
    background-size: 200% !important;
  }

  .logo-small {
    max-width: 100%;
  }

  .layout-home {
    background: transparent;
    padding: 0%;
    margin-top: -2%;
  }
}

//TABLETS
@media (max-width: 1024px) {
  .layout-home {
    background: transparent;
    padding: 0;
    margin-top: -2%;
  }

  .landing-picture-principal-responsive {
    background-size: cover;
  }

  .text-about-us-responsive {
    margin: 3rem 0px !important;
  }

  .margen-text-about {
    padding: 20px 5vw !important;
  }

  .image-about-responsive {
    width: 750px !important;
  }

  .two-thirds-responsive {
    width: 100% !important;
  }

  .one-third-responsive {
    width: 100% !important;
  }

  .top-users-responsive {
  }

  .display-none-responsive {
    display: none;
  }

  .display-none-responsive {
    display: none;
  }
}

@media (max-width: 960px) {
  .layout-home {
    background: transparent;
    padding: 0;
  }

  .logo-small {
    max-width: 100%;
  }

  .header-bar {
    justify-content: center !important;
  }

  .byp-btn-span {
    display: none !important;
  }

  .high-info-quote {
    display: none;
  }

  .high-info-quote-tablet {
    display: flex;
  }

  .home-sectioncde-home .byp-poster {
    width: 22rem !important;
  }

  .home-responsive-font-size p {
    font-size: 0.75rem !important;
  }

  .home-responsive-font-size h5 {
    font-size: 1rem !important;
  }

  .home-responsive-font-size h4 {
    font-size: 1.25rem !important;
  }

  .home-responsive-font-size h3 {
    font-size: 3.125rem !important;
  }

  .home-responsive {
    margin: 2rem !important;
    margin-top: 10px;
  }

  .home-sectionb-logo {
    transform: translateY(5rem);
    margin-top: 10px;
  }

  .home-sectionc-card,
  .home-sectiond-card,
  .home-sectione-card {
    width: 20rem !important;
  }

  .home-sectionc-card,
  .home-sectione-card {
    margin-left: 2rem !important;
    align-self: flex-start;
  }

  .home-sectionc-title,
  .home-sectiond-title,
  .home-sectione-title {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .home-card-responseive {
    width: 35rem;
  }

  .home-img-d {
    left: -15rem !important;
    top: 50% !important;
    transform: translateY(-50%);
  }

  .byp-course-overview-card__gap {
    margin-bottom: 1rem;
  }

  .show-notshow {
    display: none;
  }

  .btn-show-map {
    display: initial;
  }

  .select-age-responsive {
    width: 13% !important;
  }

  .home-btns-responsive {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0.375rem 0.9375rem;
  }

  .high-education-form-responsive > div:nth-child(1),
  .high-education-form-responsive > div:nth-child(1) > div {
    border-top-left-radius: 0.5rem !important;
  }

  .high-education-form-responsive > div:nth-child(3),
  .high-education-form-responsive > div:nth-child(3) > div {
    border-top-right-radius: 0.5rem !important;
  }

  .high-education-form-responsive > div:nth-child(4),
  .high-education-form-responsive > div:nth-child(4) > div {
    border-bottom-left-radius: 0.5rem !important;
  }

  .high-education-form-responsive > div:nth-child(7),
  .high-education-form-responsive > div:nth-child(7) > button {
    border-bottom-right-radius: 0.5rem !important;
  }

  .high-education-form-responsive > div:nth-child(1),
  .high-education-form-responsive > div:nth-child(3) {
    grid-row: 1;
    grid-column: span 3;
  }

  .high-education-form-responsive > div:nth-child(2) {
    grid-row: 2;
    grid-column: span 3;
  }

  .high-education-form-responsive > div:nth-child(4) {
    grid-row: 3;
    grid-column: span 5;
  }

  .high-education-form-responsive > div:nth-child(5) {
    grid-row: 3;
    grid-column: span 2;
    margin: 0;
  }

  .high-education-form-responsive > input {
    grid-row: 3;
    grid-column: span 2;
  }

  .high-education-form-responsive > div:nth-child(7) {
    grid-column: span 2;
  }

  .high-school-form-responsive > div:nth-child(1),
  .high-school-form-responsive > div:nth-child(2) {
    grid-row: 1;
    grid-column: span 3;
  }

  .high-school-form-responsive > div:nth-child(3) {
    grid-row: 1;
    grid-column: span 4;
  }

  .high-school-form-responsive > div:nth-child(4) {
    grid-row: 2;
    grid-column: span 10;
  }

  .high-school-form-responsive > div:nth-child(5) {
    grid-row: 3;
    grid-column: span 4;
  }

  .high-school-form-responsive > div:nth-child(6) {
    grid-row: 3;
    grid-column: span 4;
  }

  .high-school-form-responsive > div:nth-child(7),
  .high-school-form-responsive > div:nth-child(8) {
    grid-row: 3;
    grid-column: span 1;
  }

  .header-pc {
    display: none;
  }

  .header-tablet {
    display: inline;
  }

  .home-left-icon {
    text-align: center;
    border-right: 1px solid #2d2f66;
  }

  .headerMenu > div {
    margin: 0 !important;
  }

  .headerMenu > div > div:nth-child(1),
  .headerMenu > div > div:nth-child(2),
  .headerMenu > div > div:nth-child(3),
  .not-show-responsive {
    display: none;
  }

  .header-navbar-avatar {
    left: 0 !important;
    height: 100% !important;
  }

  .headerMenu > div > div:nth-child(4) {
    padding: 0 !important;
    left: 0 !important;
  }

  .no-scroll {
    overflow: hidden;
    height: 94vh;
    filter: brightness(0.5);
  }

  .no-show-footer {
    display: none;
  }

  .menu-responsive {
    display: flex !important;
  }

  .nav__menu_list {
    padding: 0 !important;
  }

  .bottomLeft-responsive {
    width: 100% !important;
    margin: 0 !important;
  }

  .byp-user-menu {
    width: 100% !important;
  }

  .btn-send-email-responsive {
    width: 100%;
    margin-left: 1rem;
  }

  .quote-title-responsive > div {
    flex: 1;
  }

  .quote-title-responsive img {
    width: 2.563rem !important;
    height: 2.563rem !important;
  }

  .quote-title-responsive > p {
    flex: 10;
  }

  .quote-institution-responsive-network-social {
    align-items: flex-start;
  }

  .headerLayout .ant-col-md-22 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .quote-agency-responsive-network-social {
    flex-direction: row;
    justify-content: flex-start;
    gap: 2rem;
  }

  .quote-agency-responsive-network-social > div {
    align-items: flex-start;
    width: 100%;
  }

  .social-networks {
    display: flex;
  }

  .program-intake-high-education {
    display: none;
  }

  .main-footer__menu {
    display: flex;
  }

  .hide_mobile {
    display: none;
  }

  .show_mobile {
    display: block;
  }
}


.responsive-select {
  width: 300% !important;
}

@media (max-width: 768px) {
  .responsive-select {
    width: 400% !important;
  }
}

@media (max-width: 800px) {
  .quotation__about {
    width: 100%;
    display: flex;
    margin-bottom: 12px;
  }

  .flex-byp__group > div {
    flex-direction: row;
    width: 100%;
  }

  .program_picture {
    display: none;
  }

  .program__btn-float,
  button {
    display: block;
    width: 100%;
    text-align: center;
  }

  .hidden-foto-celular {
    display: none;
  }

  .search_mobile_hidden {
    display: none;
  }

  .content_mobile {
    margin-top: -90px !important;
    align-items: center;
    text-align: center;
  }

  .icon_hidden_mobile {
    display: none !important;
  }

  .byp__location-title {
    margin-bottom: 10px;
  }

  .byp__location-description {
    margin-top: 10px;
    margin-bottom: -10px;
    padding: 0;
    text-align: justify;
  }

  .big-home__package > .ant-btn.ant-btn-default.slick-arrow.slick-prev {
    left: -15px !important;
    z-index: 100;
  }

  .big-home__package > .ant-btn.ant-btn-default.slick-arrow.slick-next {
    right: 0px !important;
    z-index: 100;
  }

  .margin-bloglist {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  #whatsapp-button {
    bottom: 1rem;
  }

  .ant-back-top.home-offers_back-top {
    bottom: 4.5rem;
  }

  .carrousel-container{
    width: 109%;
    padding-left: 1rem !important;
  }

  .blog__footer-button{
    width: 100%;
    padding: 0 1rem;
  }
}

//HORIZONTAL PHONE
@media (max-width: 768px) {
  .quoting-easy-responsive {
    top: 250px;
  }

  .center-responsive {
    text-align: center;
  }

  .text-landing-responsive {
    padding: 70px 25px;
  }

  .quote-description {
    margin: 0 2rem;
  }

  .course-img {
    display: none;
  }

  .course-title {
    white-space: nowrap;
    font-size: 5vw;
  }

  .btn-responsive {
    grid-template-columns: 1fr !important;
  }

  .quote-overview-table thead {
    display: none;
  }

  .text-homnesection-g {
    width: 7.5rem;
  }

  .quote-overview-table tbody tr,
  .quote-overview-table tbody,
  .quote-overview-table tbody td {
    display: block;
    width: 100%;
  }

  .quote-overview-table tbody tr {
    margin-bottom: 5px;
  }

  .quote-overview-table tbody td {
    text-align: right;
    padding-left: 50%;
    position: relative;
    border-left: 2px solid #d4d4d4;
  }

  .quote-overview-table tbody tr td:first-child {
    border-top: 2px solid #d4d4d4;
  }

  .quote-overview-table tbody tr td::before,
  .data-label {
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    text-align: left;
  }

  .quote-overview-table tbody tr td:first-child::before {
    content: "Concept";
  }

  .quote-overview-table tbody tr td:nth-child(2)::before {
    content: "Payment";
  }

  .quote-overview-table .data-label {
    display: block;
  }

  .quote-overview-table tbody tr td:nth-child(2n + 1),
  .quote-overview-table tbody tr td:nth-child(2n + 1) span {
    font-weight: 700 !important;
    color: var(--black) !important;
  }

  .big-home__info-text {
    padding-left: 1rem;
  }

  .edit__buttons {
    flex-direction: column;

    & button {
      width: 100%;
    }
  }

  .align-icon {
    margin: 0;
  }

  .big-home__currency {
    justify-content: center;
    width: 100%;
  }

  .big__form-header {
    padding: 1rem;
    flex-direction: column;
    align-items: center !important;
    justify-content: flex-end !important;
  }

  .big-home__header-responsive {
    border-radius: 10px;
    border: 1px solid #d4d4d4;
    background-color: #ffffff;
  }

  .big-home-search .ant-select-selector,
  .byp-select .ant-select-selector,
  .ant-select-show-search.ant-select:not(.ant-select-customize-input):not(
      .choose-course__input
    )
    .ant-select-selector {
    height: 3rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .big__search-responsive {
    height: 3rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .big__btn-container {
    border-radius: 10px;
    padding: 4px;
    width: 100%;
  }

  .big__search-responsive,
  .big__datepicker-responsive {
    width: 100%;
    padding: 0 0.5rem;
    background-color: #fff;

    & .ant-select-selector,
    & .ant-picker {
      border: none !important;
      border-bottom: 1px solid #d4d4d4 !important;
    }
  }

  .big-home__question {
    text-align: center;
  }

  .big__btn-search-responsive {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > p {
      display: inline-block;
      margin: 0;
      color: #fff;
    }
  }

  .title-with-icon {
    flex-direction: column;
    text-align: center;
  }

  .subtitle__big-home {
    text-align: center;
    margin: 1rem 0;
    font-size: 1rem;
  }

  .big-home__carousel-responsive {
    display: none !important;
  }

  .blog-title {
    top: 1rem !important;
    left: 50% !important;
    width: 100%;
    text-align: center;
    transform: translateX(-50%);
  }

  .blog-link {
    left: 1rem !important;
    right: 1rem;
    bottom: 1rem;
    top: auto !important;
    justify-content: center !important;
  }

  .big-home__cards-responsive {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .big-home__cards-responsive-x {
    max-width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 90%;
    gap: 1rem;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
  }

  .big-home__cards-responsive-x::-webkit-scrollbar {
    width: 0;
  }

  .big-home__cards-responsive-x > * {
    width: 100%;
  }

  .program__btn-opciones {
    width: 100%;
    justify-content: center;
  }

  .program__btn-float {
    float: none;
  }

  .programs__img-responsive {
    width: 100%;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      width: 100%;
    }
  }

  .card_language--info {
    position: absolute;
    top: 1rem;
    left: 1rem;
    bottom: 1rem;
    right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    & .gray-button {
      width: 100%;
      justify-content: center;
    }
  }

  .byp-mv-rsp {
    display: flex;
    justify-content: center !important;
    flex-wrap: wrap;
    gap: 10px !important;
  }

  .byp-jst-rsp {
    padding: 0 20px;
  }

  .byp-jst-rsp-cnt {
    text-align: center !important;
  }

  .byp-rsp-sep {
    margin-top: 1rem !important;
  }

  .buttons-quote {
    justify-content: center !important;
  }

  .quote-overview-table thead {
    display: none;
  }

  .text-homnesection-g {
    width: 7.5rem;
  }

  .quote-overview-table tbody tr,
  .quote-overview-table tbody,
  .quote-overview-table tbody td {
    display: block;
    width: 100%;
  }

  .quote-overview-table tbody tr {
    margin-bottom: 5px;
  }

  .quote-overview-table tbody td {
    text-align: right;
    padding-left: 50%;
    position: relative;
    border-left: 2px solid #d4d4d4;
  }

  .quote-overview-table tbody tr td:first-child {
    border-top: 2px solid #d4d4d4;
  }

  .quote-overview-table tbody tr td::before,
  .data-label {
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    text-align: left;
  }

  .quote-overview-table tbody tr td:first-child::before {
    content: "Concept";
  }

  .quote-overview-table tbody tr td:nth-child(2)::before {
    content: "Payment";
  }

  .quote-overview-table .data-label {
    display: block;
  }

  .quote-overview-table tbody tr td:nth-child(2n + 1),
  .quote-overview-table tbody tr td:nth-child(2n + 1) span {
    font-weight: 700 !important;
    color: var(--black) !important;
  }

  .byp-footer {
    justify-content: center;
    text-align: center;

    a {
      text-align: center;
      width: 100%;
    }
  }

  .center-responsive {
    text-align: center;
  }

  .ant-picker-panel-container .ant-picker-panels {
    flex-direction: column;
  }

  .total-quote {
    width: 50%;
  }

  .total-quote-user {
    display: flex;
    justify-content: center;
  }

  .offer-list-container {
    padding: 0 !important;
    padding-right: 25px !important;
  }

  .time-filter {
    width: 100% !important;
    display: flex !important;
    justify-content: flex-start !important;
    padding-top: 10px;
    padding-left: 5px !important;
  }

  .selects-responsives-column {
    display: flex !important;
    flex-direction: column;
  }

  .byp__location-title,
  .byp__program-title,
  .quote-byp__container h1 {
    font-size: 1.875rem;
  }

  .quote-byp__container h3 {
    font-size: 1.125rem;
  }

  .quote-byp__mb,
  .quote-byp__habilidades {
    font-size: 0.75rem;
  }

  .quote-byp__mb span {
    font-size: 1rem;
  }

  .quote-byp__container p {
    font-size: 1rem;
  }

  .byp__location-description,
  .byp__program-body {
    font-size: 0.75rem;
  }

  .images__locations--big,
  .images__locations--small {
    max-height: 18rem;
  }

  .country-content__title-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.375rem;
  }

  .country-content__subtitle-block {
    text-align: center;
    font-size: 1.125rem;
    color: #797979;
  }

  .layout-home__footer {
    & > h1 {
      font-size: 2rem;
    }

    & a,
    & button {
      width: 100%;
      text-align: center;
    }
  }

  .big-home__blog-language {
    & .blog-info {
      right: 1rem;
      bottom: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    & .blog-link {
      width: 100%;
    }
  }

  .ant-picker-header {
    & > button {
      width: auto;
    }

    .ant-picker-header-view {
      display: flex;
    }
  }

  .flex-byp__group {
    width: 100%;

    & > div {
      flex-direction: column;
      // flex-direction: column-reverse;
      width: 100%;

      & > div {
        width: 100%;

        & > button {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 690px) {
  .home-btns-responsive button:nth-child(4) {
    margin-right: 0 !important;
  }

  .home-btns-responsive button:first-child {
    margin-left: 0 !important;
  }

  .card-quoter-image {
    display: none;
  }

  .language-from {
    display: flex !important;
    flex-wrap: wrap;
  }
}

@media (max-width: 600px) {
  .logo-small {
    // min-width: 250px;
    // padding: 12px;
    // background-color: red;
    padding-left: 12px;
  }

  .space-content {
    gap: 20px 0 !important;
  }

  .layout-home {
    padding: 0 12px !important;
    margin-top: -20px;
  }

  .big__form-header {
    margin: 0 12px 12px 12px;
    padding: 0 12px;
  }

  .byp-mb-1 {
    margin-bottom: 5px;
  }

  .big-home__question,
  .big-home__currency,
  .btns__programs {
    margin: 6px 0 6px 0;
  }

  .apply-modal {
    max-width: 350px !important;
  }

  .site-layout-content {
    min-height: 280px;
    // padding: 5px 10px;
    padding: 10px 13px;
        margin: 0;
  }
}

//BIG PHONE
@media (max-width: 568px) {
  .flex-byp__group > div {
    flex-direction: column;
    width: 100%;
  }

  .big-home__question,
  .big-home__currency,
  .btns__programs {
    margin: 8px 0 4px 0;
  }

  .big-home__info {
    background-size: 300% !important;
  }

  .quote-overview__date--border-left,
  .quote-overview__date--border-right {
    width: 100%;
  }

  .quotation__footer-btns {
    & .ant-btn {
      & > span:nth-child(2) {
        display: none;
      }
    }
  }

  .quote-byp__btn-add-top,
  .quote-byp__btn {
    & > span:nth-child(2) {
      display: none;
    }
  }

  .quotation__footer-carrousel-responsive,
  .quotation__about {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    gap: 0.1rem;
    scroll-snap-type: inline mandatory;
    align-items: center;
    gap: 0.5rem;
    row-gap: 0.5rem !important;
    justify-content: space-between;
    width: inherit;
    flex-wrap: nowrap;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    padding-bottom: 1rem;
  }

  .quotation__about {
    margin-block-end: 18px;
  }

  .quotation__footer-carrousel-responsive {
    & > div {
      display: flex;
      gap: 0.5rem;
      scroll-snap-type: inline mandatory;

      & > .blog-card {
        width: 15rem;
      }
    }
  }

  .quotation__footer-carrousel-responsive::-webkit-scrollbar,
  .quotation__about::-webkit-scrollbar {
    width: 2px;
    height: 4px;
  }

  .quote-byp__school {
    margin-top: 1rem;
  }

  .quotation__description-school {
    font-size: 0.75rem;
  }

  .quote-byp__new_search {
    text-align: center;

    & h2 {
      font-size: 1.5rem !important;
      margin-bottom: 0.5rem;
    }
  }

  .carrousel_school-quote {
    display: none !important;
  }

  .cotizacion__title {
    font-size: 1.25rem;
  }

  .cotizacion__rate {
    font-size: 0.75rem;
    display: flex;
    align-items: center;
  }

  .cotizacion__avatar {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }

  .quote-course--title__responsive {
    font-size: 1.125rem;
    font-weight: 300;
  }

  .quotation__address {
    font-size: 0.75rem;
    margin-bottom: 1rem;
  }

  .quotation__title {
    font-size: 1rem;
  }

  .cotizacion__start-end {
    & > div {
      width: 100%;
    }

    & label {
      font-size: 0.75rem;
    }

    & > div:first-child {
      border-radius: 12px 12px 0 0;
    }

    & > div:last-child {
      border-radius: 0 0 12px 12px;
    }
  }

  .quotation__inputnumber {
    width: 100%;
  }

  .quotation__accomodation-question {
    margin-top: 0.5rem;
    flex-direction: column;
    align-items: flex-end;
    font-size: 0.875rem;
  }

  .radio_btn-quote {
    margin: 0;
  }

  .quote-byp__quote-panel {
    & .ant-collapse-header {
      padding: 1rem !important;
      display: grid !important;
      grid-template-columns: 10% 90%;
    }

    & .ant-collapse-extra {
      grid-column: 1/3;
      margin: 0 !important;
      margin-top: 1rem !important;

      & > p {
        font-size: 0.75rem !important;
      }
    }
  }

  .quote-byp__quote-panel {
    background-color: #fafafa;

    & .ant-collapse-content-box {
      padding: 1rem;
    }

    .quotation__table {
      display: flex;
      justify-content: space-between;
      padding: 8px;
    }

    // .quotation__table > * {
    //   flex: 1;
    //   align-self: center;
    // }

    .cotizacion-currency {
      justify-content: space-between;
    }

    .ant-statistic-content {
      text-align: center;
    }

    // & .ant-row {
    //   display: grid !important;
    //   grid-template-columns: repeat(2, 1fr);
    //   & > .ant-col {
    //     border: none;
    //   }
    // }
  }

  .quotation__marging {
    margin-top: 1rem;
  }

  .quotation__footer-btns {
    justify-content: space-between;
  }

  .quotation__footer-carrousel {
    display: none !important;
  }

  .quotation__about::-webkit-scrollbar {
    width: 2px;
    height: 4px;
  }

  .quotation__about::-webkit-scrollbar-thumb {
    background: #6a6a6a;
    max-height: 1px;
    border-radius: 6px;
  }

  .quotation__about::-webkit-scrollbar-track {
    background-color: #dcdcdc;
  }

  .description-cost {
    display: none;
  }

  .quote-byp__quote-panel .ant-row .ant-col {
    width: 50%;
  }

  .quotation__table p,
  .quotation__table span,
  .quotation__table label {
    margin: 0;
    font-size: 0.75rem;
  }

  .quote-byp__quote-panel .ant-switch {
    min-width: 34px;
    width: 34px;
    max-width: 34px;
  }

  .quotation__table {
    gap: 0.5rem;
  }

  .quote-byp__quote-panel .byp-hr {
    display: none;
  }

  .quote-byp__quote-panel .ant-form-item {
    width: 50%;
    // width: 16rem;
    font-size: 0.75rem;


  }

  .quote-byp__quote-panel .ant-collapse-content-box > .ant-row,
  .quote-byp__quote-panel .ant-collapse-content-box > div > .ant-row {
    border: 1px solid #d4d4d4 !important;
    margin: 0.5rem 0 !important;

    // mobile queries
    @media (max-width: 568px) {
      margin: auto !important;

       // Apply only to the first .ant-row
      //  &:not(:last-of-type) {
      //   border-bottom: none !important;
      // }
    // &:first-of-type {
    //   border-bottom: none !important;
    // }
    }

  
  }

  .byp-border-left {
    border: none;
  }

  .carrousel-container {
    overflow-x: scroll;
  }

  .carrousel-container::-webkit-scrollbar {
    height: 8px;
  }

  .carrousel-container > .slick-slide {
    min-width: 80%; 
    margin-right: 5px;
  }

  .confirmation {
    margin-top: 0;
  }
}

//MEDIUM PHONE
@media (max-width: 480px) {
  .logo-small {
    max-width: 100%;
  }

  .ant-table-content,
  .ant-statistic-content-value,
  .ant-statistic-content-prefix {
    font-size: 0.65rem;
  }

  .quote-overview-totals .ant-table-cell {
    font-size: 0.773rem;
  }

  .mobile-home{
    width: auto;
    padding-left: 0rem !important;
  }
}

@media (max-width: 425px) {
  .space-content {
    gap: 40px 0 !important;
  }

  .big-home__info {
    background-size: 300% !important;
  }

  .selects-responsive {
    flex-direction: column;
  }

  .mb-responsive {
    padding-top: 350px;
  }

  .mb-responsive {
    padding-top: 400px;
  }

  .quoting-easy-responsive {
    bottom: 200px !important;
    display: flex;
  }
}

//PHONE
@media (max-width: 390px) {
  h1 {
    font-size: 34px;
  }

  h4 {
    line-height: 28px;
    font-size: 23px;
  }

  .ant-avatar {
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 50%;
  }

  .anticon,
  .anticon-menu {
    width: 35px;
  }

  .ant-dropdown-trigger {
    border-radius: 8px !important;
    padding: 0px 4px !important;
    gap: 10px !important;
  }

  .big-home__question,
  .big-home__currency,
  .btns__programs {
    margin: 6px 0 2px 0;
  }

  // TODO: Change then the margin bottom to 0px
  .byp-mb-1 {
    margin-bottom: 0px;
  }

  .quote-apply-rate .ant-rate-star:not(:last-child) {
    margin-right: 0px;
  }

  .big-home__info {
    background-size: 330% !important;
  }

  .space-content-my-courses {
    gap: 10px 0 !important;
  }

  .mobile-home{
    width: 104%;
    padding-left: 1rem !important;
  }
}

@media (max-width: 360px) {
  .big-home__info {
    background-size: 390% !important;
  }

  .home-sectioncde-home .byp-poster {
    display: none;
  }

  .mb-responsive {
    padding-top: 400px;
  }

  .quote-title-responsive > p {
    flex: 8;
  }

  .dontShowMenu {
    display: none;
  }

  .show-phone-theme {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    margin: 0 1rem;
    gap: 2rem;
  }

  .navbar__links {
    display: none !important;
  }

  .home-responsive {
    margin: 2rem !important;
  }

  .home-sectionb-responsive {
    padding: 7rem 2rem !important;
  }

  .home-sectionb-logo {
    transform: translateY(16rem);
  }

  .home-sectiong-hidden {
    display: none;
  }

  .home-sectionc-card,
  .home-sectiond-card,
  .home-sectione-card {
    width: 100% !important;
    margin: 0 !important;
  }

  .home-sectionc-title,
  .home-sectiond-title,
  .home-sectione-title {
    text-align: center;
  }

  .home-sectionc-title p,
  .home-sectiond-title p,
  .home-sectione-title p {
    text-align: left;
  }

  .home-sectionc-title > img,
  .home-sectiond-title > img,
  .home-sectione-title > img {
    margin-bottom: 3rem !important;
    margin-top: 1.25rem !important;
  }

  .home-card-responseive {
    width: auto !important;
    margin: 0 2rem !important;
    padding: 2rem;
    min-height: 50rem !important;
  }

  .home-sectioncde-home {
    flex-direction: column;
  }

  .mb-responsive {
    margin-bottom: 10rem !important;
    padding-top: 400px;
  }

  .mb-responsive-lg {
    margin-bottom: 16rem !important;
  }

  .home-sectioncde-home .byp-poster {
    width: 22rem !important;
  }

  .home-img {
    display: flex;
    justify-content: center;
  }

  .home-img-c {
    margin: -8rem 0 !important;
  }

  .home-img-d {
    bottom: -25rem;
    left: initial !important;
    top: initial !important;
  }

  .home-responsive-font-size p {
    font-size: 0.75rem !important;
  }

  .home-responsive-font-size h5 {
    font-size: 1rem !important;
  }

  .home-responsive-font-size h4 {
    font-size: 1.25rem !important;
  }

  .home-responsive-font-size h3 {
    font-size: 3.125rem !important;
  }

  .home-links-responsive {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .home-logo-j {
    margin-top: 40rem !important;
    z-index: 1;
  }

  .home-btn-a-responsive {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 90px 3rem;
  }

  .margin-top-a-responsive {
    margin-top: 2.25rem !important;
  }

  .home-sectionc-title,
  .home-sectiond-title,
  .home-sectione-title {
    flex-direction: column;
  }

  .quote-agency-img {
    width: 4rem;
  }

  .no-scroll {
    overflow: hidden;
    height: 86vh;
    filter: brightness(0.5);
  }

  .nav__menu_list {
    padding: 0 2rem !important;
  }

  .byp-mv-rsp {
    margin-top: 1rem;
  }

  .total-quote {
    width: 100%;
  }

  .selects-responsive {
    flex-direction: column;
  }

  .quoting-easy-responsive {
    bottom: 50px;
  }

  .mb-responsive {
    padding-top: 250px !important;
  }

  h1 {
    font-size: 32px;
  }

  h4 {
    line-height: 24px;
    font-size: 20px;
  }

  .space-content {
    gap: 20px 0 !important;
  }
}

@media (max-width: 320px) {
  .total-quote {
    width: 100%;
  }

  .total-quote-user {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .ant-page-header {
    padding: 10px 10px;
  }

  .display-none-responsive {
    display: none;
  }

  .total-quote {
    width: 100%;
  }

  .total-quote-user {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .ant-page-header {
    padding: 10px 10px;
  }

  .display-none-responsive {
    display: none;
  }
}

// style={{ marginLeft: '3.13rem', marginRight: '30px'
.mobile-margin {
  margin-left: 3.13rem;
  margin-right: 30px;
}

// MOBILE
@media (max-width: 568px) {
  .mobile-margin {
    margin-left: auto;
    margin-right: auto;
    // margin-top: 20px !important;
  }

  // .mobile-margin_card {
  //   margin-left: -20px !important;
  //   margin-right: -20px !important;
  //   margin-top: 10px !important;
  // }

  .info-quote__container {
    & h2 {
      font-size: 1.25rem;
    }

    & > p {
      margin-top: 5px;
      font-size: 0.75rem;
    }

    & .ant-col span {
      font-weight: 700;
    }

    & .ant-col p {
      font-weight: 400;
    }

    & .radio_span span {
      color: #1a171e;
      font-size: 0.875rem;
      font-weight: 400;
    }

    & .radio_span .ant-form-item-label {
      max-width: 50%;
    }

    & .radio_span .ant-form-item-control {
      max-width: 50%;
      text-align: right;
    }

    .ant-radio-inner::after {
      background-color: #ff395c;
    }

    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
      border-color: #ff395c;
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: #ff395c;
    }
  }

  .info-quote__terminos {
    & p {
      font-size: 0.75rem;
    }

    &-span {
      color: #ff395c;
      font-size: 0.75rem;
      font-weight: 400;
      text-decoration: underline;
    }

    .terminos-desktop {
      display: none !important;
    }

    .terminos-mobile {
      display: block !important;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .component_none {
    display: none !important;
  }

  .quote__overview-info {
    & h2 {
      font-size: 1.25rem;
      font-weight: 600;
      color: #3b3b3b;
    }
  }

  .quote__overview-rate {
    display: none;
  }

  .margin-opinion {
    margin-left: -20px !important;
  }

  .txt-center {
    text-align: center !important;
  }

  .mobile-center {
    justify-content: center !important;

    & span {
      font-size: 0.6875rem;
    }
  }

  .quote-overview__prices {
    display: flex !important;
    justify-content: center !important;
    text-align: center !important;
    font-size: 1.56rem;
    font-weight: 700;

    .custom-statistic span {
      font-size: 1.56rem;
      font-weight: 700;
    }

    .custom-statistic2 span {
      font-size: 1rem;
    }
  }

  .quote_paypal {
    display: flex !important;
    justify-content: center !important;
  }

  .text_end {
    text-align: end !important;
  }

  .confirmation2 {
    justify-content: center !important;
    text-align: center;
  }

  .button-whats {
    width: 50px;
    height: auto;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }

  .school-logo {
    display: none;
  }

  .my-courses {
    margin: 0 0.688rem !important;
    padding: 0;
    
    .my-courses-title{
      font-size: 1.625rem;
    }
  }

  .my-courses-container .card-user-account {
    margin: 1.25rem 0;
    border-radius: 20px;
  }

  .my-quote-tabs {
    flex-direction: column;
  }

    .my-quote-direction{
      display: flex;
      flex-direction: column;
  }

  .my-quote .course-title {
    white-space: normal;
  }

  .btn__back {
    height: 26px;
  }

  .title-aplication{
    font-size: 1.25rem;
  }
}

@media (min-width: 768px) {
  .referencia_mobile {
    display: none;
  }
}