.byp-modal-error {
    max-width: 60vw;
}

.byp-modal-error .modal-error-details .ant-collapse-header {
    border: 0 !important;
    background: transparent !important;
    padding: 0 !important;
    margin-top: 1rem !important;
}

.byp-modal-error .modal-error-details .ant-collapse-content-active {
    border: 0 !important;
    background: transparent !important;
    padding: 0 !important;
    margin-bottom: 0;
}

.byp-modal-error .modal-error-details .ant-collapse-content-box {
    border: 0 !important;
}
@primary-color: #2460B7;@link-color: #2460B7;@border-radius-base: 10px;@box-shadow-base: 0 9px 28px 8px rgba(0, 0, 0, 0.05);