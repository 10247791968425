.byp-editor {
  // background-color: brown;
  border-top: 1px solid var(--full-gray);
}

.byp-editor-wrapper {
  // background-color: blue;
}

.byp-editor-toolbar,
.byp-editor-toolbar .rdw-option-wrapper {
  background-color: transparent;
}

.byp-editor-toolbar .rdw-option-wrapper.rdw-option-active {
  border: solid var(--red-50) 1px;
}
@primary-color: #2460B7;@link-color: #2460B7;@border-radius-base: 10px;@box-shadow-base: 0 9px 28px 8px rgba(0, 0, 0, 0.05);