
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  margin: 0;
    font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins', sans-serif !important;
}

.ant-menu-submenu-selected, .ant-menu-submenu-selected > [style="padding-left: 48px;"] {
  background-color: #2D2F66 !important;
}

.ant-menu-submenu-selected > div > span, .ant-menu-submenu-selected > div > svg ,.ant-menu-submenu-selected > div > i{
  color: #F7F7F7;
}

