h1 {
  font-size: 3.052em;
  color:#373737;
  font-weight: 600;
}

h2 {
  font-size: 2.441em;
  color:#373737;
}

h3 {
  font-size: 1.953em;
  color:#373737;
}

h4 {
  font-weight: 600; 
  line-height: 33px;
  font-size: 22px; 
}

h5 {
  font-size: 1.25em;
  color:#373737;
}

h6 {
  font-size: 1.05em;
  color:#373737;
}

body {
  background: var(--background);
}

.ant-tag {
  border-radius: .4rem;
}

p{
  color: #373737;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.byp-tag {
  background-color: var(--gray-50);
  color: var(--blue-200);
  border: transparent;
}

.p-1 {
  padding: .5rem;
}

.pl-1 {
  padding-left: .5rem;
}

.pr-1 {
  padding-right: .5rem;
}

.pt-1 {
  padding-top: .5rem;
}

.pb-1 {
  padding-bottom: .5rem;
}

.px-1 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.bg-white {
  background-color: var(--full-white) !important;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px !important;
}

.upper {
  text-transform: uppercase;
}

.fw-n {
  font-weight: normal !important;
}

.fw-3 {
  font-weight: 300 !important;
}

.fw-4 {
  font-weight: 400 !important;
}

.fw-5 {
  font-weight: 500 !important;
}

.fw-6 {
  font-weight: 600 !important;
}

.fw-7 {
  font-weight: 700 !important;
}

.underline {
  text-decoration: underline;
}