.vimeo-form form {
  // background-color: brown;
}

.vimeo-form input {
  // background-color: chartreuse;
}

.vimeo-form .input-file {
  z-index: -1;
  position: absolute;
}

.vimeo-form input::file-selector-button {
  z-index: -1;
  opacity: 0;
  width: 1px;
}

.vimeo-form label {
  // background-color: blue;
}

.vimeo-thumbnail {
  position: relative;
  display: grid;
  place-items: center;
  // background-color: #8f8f8f;
  & > img {
    max-width: 100%;
    height: 21rem !important;
    object-fit: cover;

    @media (max-width: 767px) {
      height: 100% !important;
    }
  }
  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.5rem;

    display: grid;
    place-items: center;
    height: 3rem !important;
    aspect-ratio: 2/1;
    background-color: #303030;
    color: #FFFFFF;
    border-radius: 6px;
    cursor: pointer;

    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2); 
  }
}
@primary-color: #2460B7;@link-color: #2460B7;@border-radius-base: 10px;@box-shadow-base: 0 9px 28px 8px rgba(0, 0, 0, 0.05);