.user-analysis__select .ant-select-selector {
    background-color: var(--blue-200) !important;
}

.user-analysis__select {
    margin: 0;
}

.user-analysis__select .ant-select-selection-item,
.user-analysis__select .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
}

.user-analysis__select .ant-select-selection-item {
    color: var(--white) !important;
    padding: 0 !important;
    background-color: var(--blue-200);
}

.user-analysis__select .ant-select-selection-placeholder {
    color: var(--white) !important;
}

.user-analysis__select .ant-select-arrow {
    color: var(--white) !important;
}

.user-info-card {
    min-height: 10rem;
    padding: 0.5rem 2rem;
}

.card-user {
    border-radius: 0.75rem;
    background-color: var(--full-white);
    display: flex;
    justify-content: center;
    padding: 1.188rem 0;
}

.user-average {
    border-radius: 0.75rem;
    background-color: var(--full-white);
    padding: 1.188rem;
    height: 100%;
}

.program-average {
    border-radius: 0.75rem;
    padding: 1.188rem;
    background-color: var(--white);
}

.card-user__item {
    padding: 0 30px;
    border-right: 1px solid #CDDFFF;
}

.card-user__item--last {
    padding: 0 30px;

    &-btn {
        padding-left: 0;
    }
}

.user-img {
    width: 6.875rem;
    height: 6.875rem;
    border-radius: 50%;
    overflow: hidden;
}

.user-information {
    gap: 1rem;
}

.user-info {
    gap: 1rem;
}

.user-info .ant-progress-text {
    font-size: 0.75rem !important;
    color: #6495ED !important;
}


.total-quote-percentage {
    color: #6495ED;
}

.total-quote-user {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
}

.display-image {
    justify-content: center;
    width: 6.875rem;
    height: 6.875rem;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
}

.display-information {
    padding: 0 30px;
    border-right: 1px solid #CDDFFF;
}

.display-card {
    width: 100%;
    min-height: 10rem;
}

.display-card-bottom {
    width: 100%;
    height: 100%;
    border-radius: 0.75rem;
    background-color: var(--full-white);
    display: flex;
    justify-content: center;
    padding: 0.594rem 0;

    & h4 {
        margin: 0;
        margin-bottom: 0.75rem;
        margin-top: 0.375rem;
    }

    & p {
        margin: 0;
    }

    & p.byp-text-md {
        margin-bottom: 0.375rem;
    }
}

.header-section1 {
    display: flex;
    gap: 1.5vw;
    align-Items: center;
    padding-left: 0;
}

.header-section2 {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 0;
    gap: 2vw;
    align-items: center;
}

.header-client-quotes {
    display: flex;
    padding-top: 7px;
    padding-left: 70px;
    padding-right: 70px;
}

.course-title-client-quotes {
    display: flex;
    width: 100%;
    white-space: nowrap;
    padding-top: 20px;
    padding-right: 1vw;
    align-items: center;
}

.client-quotes-buttoms {
    width: 33%;
}

.client-quotes-buttoms-book {
    width: 33%;
    display: flex;
}

.client-quotes-see-share {
    justify-content: flex-end;
    width: 33%;
}

.client-quotes-height {
    height: 80px;
}

.show-Footer-client-quotes {
    background-color: var(--blue-100);
    width: 100%;
    height: 124px;
    display: flex;
    flex-direction: row;
    position: sticky;
    bottom: 0px;
}

.footer-social-networks {
    margin: auto;
    width: auto;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 768px) {

    .footer-social-networks {
        flex-wrap: wrap;
        justify-content: space-evenly;
        padding-left: 20px;
    }

    .show-Footer-client-quotes {
        flex-direction: row-reverse;
    }

    .display-none {
        display: none;
    }

    .client-quotes-height {
        height: 120px;
    }

    .client-quotes-see-share {
        width: 100%;
        display: flex;
        padding-top: 15px;
        justify-content: space-around;
    }

    .course-agent {
        font-size: 2.9vw !important;
    }

    .client-quotes-buttoms {
        width: 50%;
    }

    .client-quotes-buttoms-book {
        width: 50%;
        display: flex;
    }

    .course-title-client-quotes {
        flex-direction: column;
    }

    .total-quote-user {
        grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
    }

    .total-quote-buttons {
        grid-template-columns: repeat(auto-fit, minmax(100%, 3fr));
    }

    .display-image {
        display: none;
    }

    .display-card-bottom {
        width: 100%;
        height: 100%;
        border-radius: 0.75rem;
        background-color: var(--full-white);
        display: grid;
        justify-content: center;
        padding: 1.188rem 0;
    }

    .display-information {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }

    .display-card {
        width: 100%;
        min-height: 10rem;
        padding: 0 0;
    }

    .header-client-quotes {
        display: flex;
        flex-direction: column;
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .header-section1 {
        display: flex;
        align-Items: center;
        justify-content: space-around;
    }

    .header-section2 {
        justify-content: space-around;
        display: flex;
    }

    .client-quotes-stars-section {
        justify-content: space-between !important;
    }

    .client-quotes-section-quotes {
        margin: 0 10px !important;
    }

    .client-quotes-second-section {
        border-radius: 50px;
    }

    .client-quotes-title-section-mobile {}

}

.client-quotes-title-section-mobile {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.client-quotes-section-quotes {
    margin: 0 15vw;
    position: relative;
    top: -160px;
}

.client-quotes-second-section {
    background-color: #F7F7F7;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    width: 100%;
    padding-left: 1.5rem;
    display: flex;
    flex-direction: column;
}

.client-quotes-title-row {
    display: flex;
    flex-Wrap: nowrap;
    align-items: flex-end;
}

.client-quotes-stars-section {
    display: flex;
    align-items: center;
    padding-top: 20px;
}

.total-quote-buttons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
}

.user-analysis-total {
    cursor: pointer;

    .quote-list-item-card.active {
        border: 4px solid #2460B7;
    }
}

.popular-range-percentage {
    color: #2D2F66;
}

.card-first-item,
.card-last-item {
    justify-content: center;
    flex: 1;
}

.quote-top-users {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-direction: row;
}

.quote-most-popular-schools {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    text-align: center;
}

.program-average-card {
    border-radius: 0.75rem;
    background-color: var(--full-white);
    padding: 1.188rem;
    margin: 0.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.average-gap-1 {
    gap: 1rem
}

.average-gap-0-5 {
    gap: 0.5rem
}

.byp-my-0-5 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.byp-my-0-5-vh {
    margin-top: .5vh;
    margin-bottom: .5vh;
}

.byp-my-1-vh {
    margin-top: 1vh;
    margin-bottom: 1vh;
}

.byp-text-0-8 {
    font-size: 0.875rem;
}

.program-average-card.active {
    border: 1px solid #6495ED;
    background-color: rgba(205, 223, 255, 0.25);
}

.hide-input {
    border: none;
    background: inherit;
    padding: 1rem 0px;
}

.hide-input>.ant-picker-input,
.hide-input>.ant-picker-range-separator,
.hide-input>.ant-picker-input,
.hide-input>.ant-picker-suffix,
.hide-input>.ant-picker-clear {
    display: none;
}

.table-total-quote {
    min-width: 100%;
    table-layout: fixed;

    thead {
        background: #D4D4D4;
        color: #1A171E;
        font-size: 0.688rem;
    }

    th,
    td {
        padding: 0.2rem;
    }

    tbody {
        font-size: 0.813rem;
        color: #1A171E;

        a {
            color: #2460B7;
            text-decoration: underline;
        }
    }
}

.table-total-quote-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.btns-calendar {
    position: relative;
}

.calendar-input {
    position: absolute;
    top: 0;
    left: 0;
}

.btns-timer {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.btns-timer .byp-btn-back {
    margin: 0;
}

.reports-uni-selects {
    width: 100% !important;
    margin: 0 !important;
}

.reports-uni-selects-container {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.5rem;
    width: 100%;
}

.multi-select .ant-select-selector {
    overflow-y: auto;
    height: 100%;
    padding-bottom: 0 !important;
    background-color: #f5f5f5 !important;
}

.multi-select .byp-mb-1 {
    margin: 0;
}

.multi-select .ant-select-selection-item-content {
    color: #2D2F66;
}

.multi-select .ant-select-selection-item {
    border: none;
    background-color: var(--gray-50);
}

.title-multi-select {
    color: var(--blue-200);
}

.title-reports-uni {
    color: var(--black);
    margin-bottom: 2rem;
}

.title-reports-uni span {
    font-size: 1rem;
    font-style: oblique;
    font-weight: normal;
}

@media (max-width: 768px) {
    .table_my_reports {
        width: 62.5rem;
    }
}

.quote-list-card {
    min-height: 9rem !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    padding-top: 0.906rem !important;
    padding-bottom: 0.906rem !important;
    padding-right: 20px !important;
    padding-left: 20px !important;

    & > .user-info {
        padding-left: 0.625rem;
    }
}

.agency-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
    text-align: center;
}

.agency-profile-contener {
    width: 100%;
    display: flex;
}

@media (max-width: 768px) {
    .quote-list-card {
        min-height: 100% !important;
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .agency-profile {
        padding-bottom: 80px;
    }

    .agency-profile-contener {
        flex-direction: column;
        align-items: center;
    }

}

.top-agencies-right {
    display: flex;
    align-items: center;
    margin-bottom: 0.875rem;

    & .top-agencies-num {
        color: #2D2F66;
        font-weight: 700;
        font-size: 1.375rem;
        line-height: 22px;
        margin: 0;
    }
}


.card-top-agencie {
    background-color: #FFFFFF;
    border-radius: 13px;
    margin-bottom: 1rem;

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.625rem 1.5rem;
    }

    & .card-img {
        max-width: 6.875rem;
        width: 6.875rem;
        max-height: 6.875rem;
        height: 6.875rem;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
    }

    & h3 {
        font-size: 1rem;
        color: #2460B7;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 0.625rem;
    }

    & h4 {
        color: #8D8D8D;
        font-size: 0.75rem;
        font-weight: 500;
        margin: 0;
        line-height: 16px;
    }

    & p {
        color: #2C3E50;
        font-size: 1rem;
        line-height: 22px;
        margin-bottom: 0;
    }

    & .card-quote-created {
        border-right: 1px solid #adadad;
    }

    & .card-quote-created,
    & .card-quote-sended {
        padding: 0.25rem;
        margin-bottom: 0.625rem;
    }
}

@media (max-width: 768px) {
    .card-top-agencie {
        & h3 {
            text-align: center;
        }
    }
}

.agencies-analitics__slects {
    background-color: #2D2F66;
}

.selec-agencie-analitics {
    position: relative;
    cursor: pointer;

    &:hover .ant-select-arrow {
        display: none;
    }

    &>button,
    &>button:hover {
        display: none;
        position: absolute;
        border: none;
        background-color: inherit;
        color: #FFFFFF;
        top: 0;
        right: -0.4rem;
        font-size: 0.75rem;
    }

    &:hover>button,
    &:hover>button:hover {
        display: inline-block;
    }
}

.display-none {
    display: none;
}