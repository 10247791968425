/* Force update ant style */
.float-social .ant-input{
  z-index: 0 !important;
}

.float-label .ant-input,
.float-label .ant-input-number-input{
  padding: 16px 12px 0px 12px;
}

.float-label .ant-select .ant-select-selector {
  padding: 16px 10px 4px 11px;
}

.float-label .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 16px 10px 4px 11px;
  height: 48px;
}

.float-label .ant-select-single .ant-select-selector .ant-select-selection-search {
  top: 16px;
}

.float-label {
  position: relative;
}

.label {
  font-size: 1rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: .7rem;
  top: .7rem;
  transition: 0.2s ease all;
  color: @input-color-blue;
}

label.label-float {
  top: 3px;
  font-size: .75rem !important;
}

.float-social .label {
  left: 4rem;
}