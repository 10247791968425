.price-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 5px;
}

.price {
    color: #000000;
    background-color: var(--full-white);
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    // margin-left: 6px;
    display: flex;
    align-items: center;
    padding: 0 5px;

    svg {
        margin-left: 7px;
    }
}

.price-before {
    opacity: .8;
    font-size: 13px;
}

.discount {
    display: flex;
    flex-direction: column;
    // background-color: #FF395C;
    color: white;
    font-size: 12px;
    // padding: 1px 8px;
    border-radius: 6px;
    font-weight: 700;
    justify-content: center;

}

.card-course {
    & .card-course-column {
        min-height: 10rem;
        // background-color: red;
    }

    & .card-course__image {
        object-fit: cover;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        max-width: 100%;
        min-height: 100%;
        width: 100%;
        height: 100%;

        @media (max-width: 768px) {
            border-bottom-left-radius: 0;
        }

    }

    & .carousel-item {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
        height: 100%;
    }
}

// .card-course--favorite {
    
//     // mobile queries
//     @media (max-width: 768px) {
//         display: none;
//     }
// }

@media (max-width: 768px) {
    .card-course {
        .card-course-column {
            min-height: 16rem;
        }

        img {
            object-fit: cover;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            border-bottom-left-radius: 0;
            max-width: 100%;
            min-height: 100%;
        }

        .carousel-item {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;

            border-bottom-left-radius: 0;
        }
    }
}

.card-package {
    border-radius: 20px;
    position: relative;
    background-color: #F4F4F4;
    overflow: hidden;
    cursor: auto !important;

    /**NO HACEN NADA
    .card-package-gallery {
        min-height: 15rem;
        display: flex;
        justify-content: center;
        & > .ant-image {
            width: 100%;
        } 
    }

    img {
        object-fit: cover;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 0;
        max-width: 100%;
        min-height: 100%;
    }**/

    .slick-list {
        padding: 0;
    }

    .carousel-item {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        border-bottom-left-radius: 0;
        height: 100%;
        margin-top: -7px;
    }
}

.offer-discount-amount {
    display: flex;
    // flex-direction: column;
    background-color: #FF395C;
    color: white;
    font-size: 16px;
    padding: 1px 8px;
    border-radius: 6px;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    line-height: 20px;

    @media (max-width: 2000px) {
        max-width: fit-content;
    }
}
@primary-color: #2460B7;@link-color: #2460B7;@border-radius-base: 10px;@box-shadow-base: 0 9px 28px 8px rgba(0, 0, 0, 0.05);