.byp-dashboard-top-bar {
    background-color: transparent;
    line-height: 25px;
    .ant-menu-item, .ant-menu-submenu {
        background-color: var(--full-gray);
        border-radius: 0.4rem;
        padding: 0px 10px !important;
        font-size: 10px;
        margin-right: 5px;
        a, span {
            color: var(--full-white);
            font-weight: bold;
        }
    }
    .ant-menu-item-selected, .ant-menu-submenu-selected, .ant-menu-item:hover, .ant-menu-submenu:hover {
        background-color: var(--blue-50) !important;
    }
}

.ant-menu-submenu-popup > .ant-menu {
    background-color: var(--blue-50);
    a, span {
        color: var(--full-white);
        font-weight: bold;
    }
    .ant-menu-item, .ant-menu-item:hover, .byp-dashboard-top-bar-subitem {
        background-color: transparent !important;
        line-height: 25px;
        height: 26px;
        border-radius: 0.4rem;
        padding: 0px 10px !important;
        font-size: 10px;
        margin-right: 5px;

        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .ant-menu-item:hover .ant-menu-title-content, .ant-menu-item-selected .ant-menu-title-content{
        border-bottom: solid 1px var(--full-white);
    }
}
@primary-color: #2460B7;@link-color: #2460B7;@border-radius-base: 10px;@box-shadow-base: 0 9px 28px 8px rgba(0, 0, 0, 0.05);