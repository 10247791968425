.site-card-wrapper{
    width: 100%;
    padding: 10px;
    overflow: hidden;
    font-size: 11px;
}

.site-card-wrapper h5, .site-card-wrapper h4{
    color: #2d2f66;
}

.grid-wrapper{
    width: 100%;
    // height: 220px;
    border-radius: 13px;
    display: grid;
    gap: 13px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 13px;
    overflow: hidden;
    grid-template-areas:
    "item1 item1 item2 item3"
    "item1 item1 item4 item5"
    ;
}

.item{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.item1{
    grid-area: item1;
    // max-height: 17rem;
    overflow-x: auto;
    // iframe {

    // }
}

.item2{
    grid-area: item2;
}

.item3{
    grid-area: item3;
}

.item4{
    grid-area: item4;
}

.item5{
    grid-area: item5;
}

.user-regards{
    width: 100%;
    padding: 1.5rem;
    background-color: white;
    border-radius: 13px;
    margin-bottom: 13px;
}

.user-regards p{
    color: #2d2f66;
}

.quote-data{
    width: 100%;
    display: flex;
    gap: 13px;
}

.quote-area-one{
    width: 45%;
    background-color: white;
    border-radius: 13px;
    padding: 1.5rem;
}

.real-price{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    gap: 13px;
}

.txt-dec{
    text-decoration: line-through;
}

.deadline{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    gap: 13px;
    position: relative;
}

.deadline span{
    font-size: 0.6rem;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #f2a706;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -10px;
    right: -10px;
}

.txt-deadline{
    font-weight: bold;
}

.date-travel{
    width: 100%;
    border: 1px solid rgba(128, 128, 128, 0.5);
    border-radius: 13px;
    display: flex;
    align-items: center;
}

.date-travel p{
    margin: 0;
    font-size: 0.7rem;
    color: #2d2f66;
}

.date-travel strong p{
    color: black;
}

.in-date{
    width: 50%;
    padding-left: 1.5rem;
    border-right: 1px solid rgba(128, 128, 128, 0.5);
}

.out-date{
    width: 50%;
    padding-left: 1.5rem;
}

.quote-list-container{
    width: 100%;
    padding-top: 1.5rem;
}

.quote-buttons-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0;
}

.update-quote-btn{
    border: 1px solid #2460b7;
    color: #2460b7;
    padding: 0.2rem 1rem;
    cursor: pointer;
    font-size: 0.7rem;
}

.send-quote-btn{
    border: 1px solid #2460b7;
    background-color: #2460b7;
    color: white;
    padding: 0.2rem 1rem;
    cursor: pointer;
    font-size: 0.7rem;
}

.quote-area-two{
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 13px;
}

.quote-area-two-a{
    width: 100%;
    height: 50%;
    border-radius: 13px;
    background-color: white;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
}

.map-quote-area{
    width: 100%;
    height: calc(100% - 61.5px);
    background-image: url("https://learn.microsoft.com/es-es/azure/azure-maps/media/migrate-google-maps-web-app/google-maps-marker.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 13px;
    overflow: hidden;
}

.quote-area-two-b{
    width: 100%;
    height: 50%;
    border-radius: 13px;
    background-color: white;
    padding: 1.5rem;
}

.campus-gallery{
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: space-between;
}

.img-campus-one{
    width: 120px;
    height: 120px;
    border-radius: 13px;
    background-image: url("https://www.unav.edu/documents/10162/0/campus-pamplona.png/d5e15b74-0150-30cf-8d9a-e7a018e0ad28?t=1616429000412");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.img-campus-two{
    width: 120px;
    height: 120px;
    border-radius: 13px;
    background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSu9CpnpeW42sEXhReTJLsZ_gPbH4zJ0CP4mg&usqp=CAU");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.img-campus-three{
    width: 120px;
    height: 120px;
    border-radius: 13px;
    background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMzQzUCt8gWO9AJA7-qiuaj0pYOUwLDyDI3g&usqp=CAU");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.you-can-discover{
    width: 100%;
    padding: 1.5rem 0;
}

.tags-discover-container{
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 1.5rem 1.25rem;
    color: #2460b7;
}

.quote-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 0.25rem 0.938rem;
    background: #F4F4F4;
    border: 1px solid #2460B7;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 6px;
    font-weight: 700;
}

.quote-btn > span {
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #2460B7;
}

.edulynks-inf{
    width: 100%;
    background-color: white;
    border-radius: 13px;
    display: flex;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
}

.edl-inf-a{
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}

.edl-inf-a p, h4{
    margin: 0;
}

.edl-inf-a button{
    border: 1.5px solid #2460b7;
    border-radius: 2px;
    width: 80px;
    height: 35px;
    color: #2460b7;
    cursor: pointer;
    font-size: 0.7rem;
}

.edl-logo{
    width: 100%;
    height: 50px;
    background-image: url("https://www.lainter.edu.mx/wp-content/uploads/2021/09/Edulynks-logo.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.byp-bg-logo {
    width: 100%;
    height: 50px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.byp-bg-logo2{
    width: 100%;
    height: 100px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.social-container-edu{
    width: 100%;
    display: flex;
    gap: 10px;
}

.social-container-edu span{
    width: 20px;
    height: 20px;
    background-color: #6495ed;
    border-radius: 50%;
}

.edl-inf-b{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1.5rem;
}

.foot-img{
    width: 100%;
    height: 200px;
    border-radius: 13px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.carrousel-gallery {
  border-radius: 20px;
  overflow: hidden;
}

.carousel-100,
.carousel-100 .ant-carousel,
.carousel-100 .ant-carousel .slick-slider,
.carousel-100 .ant-carousel .slick-slider .slick-list,
.carousel-100 .ant-carousel .slick-slider .slick-list .slick-track,
.carousel-100 .ant-carousel .slick-slider .slick-list .slick-track .slick-slide,
.carousel-100 .ant-carousel .slick-slider .slick-list .slick-track .slick-slide div {
    height: 100%;
    min-height: 14rem;
}

.carousel-w-100,
.carousel-w-100 .ant-carousel,
.carousel-w-100 .ant-carousel .slick-slider,
.carousel-w-100 .ant-carousel .slick-slider .slick-list,
.carousel-w-100 .ant-carousel .slick-slider .slick-list .slick-track,
.carousel-w-100 .ant-carousel .slick-slider .slick-list .slick-track .slick-slide,
.carousel-w-100 .ant-carousel .slick-slider .slick-list .slick-track .slick-slide div {
    width: 100%;
}

//NUEVO -> PARA PORTADA CON VIDEO
.fotos-portada{
    display: inline-flex;
    width: 100%;
    height: 400px;
    align-items: center;
    gap: 10.5px;
    flex-shrink: 0;
    border-radius: 12.6px;
    overflow: hidden;
    margin: -20px 0;
}

.video-preview{
    align-self: stretch;
    width: 60%;
    height: 100%;
    div{height: 400px;}
    .vimeo-thumbnail{
        position: relative;
        display: flex;
        place-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
    }
    .vimeo-thumbnail > img {
        min-width: 100%;
        height: 400px !important;
        object-fit: cover;
    }
    .vimeo-thumbnail__play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 2.5rem;
        display: grid;
        place-items: center;
        height: 3rem !important;
        aspect-ratio: 1/1;
        background-color: #303030;
        color: #FFFFFF;
        border-radius: 1000px;
        cursor: pointer;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    }
}

.two-pics{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10.5px;
    flex: 1 0 0;
    align-self: stretch;
    width: 40%;
    height: 100%;
    div{
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.modal-video {
    position: absolute;
    top: 2%;
    left: 50%;
    -ms-transform: translate(-50%, 2%);
    transform: translate(-50%, 2%);
    width: 1080px !important;
    height: auto;
    padding: 0 !important;

    .ant-modal-body{padding: 0;}
    .ant-modal-footer{display:none;}
  }

//RESPONSIVE
// tablet format

@media (max-width: 840px) { 
    .site-card-wrapper .grid-wrapper{
       // display: flex;
        //flex-direction: column;
        height: auto;
        grid-template-areas:
        "item1 item1"
        "item2 item3"
        "item4 item5"
        
        ;
    }
    .site-card-wrapper .item{
        height: 12rem;
    }

    .video-preview{width: 100%;}
    .two-pics{display: none;}
} 

@media (max-width: 768px) { 
    .site-card-wrapper .grid-wrapper{
       // display: flex;
        //flex-direction: column;
        height: auto;
        grid-template-areas:
        "item1 item1"
        "item2 item3"
        "item4 item5"
        
        ;
    }
    .site-card-wrapper .item{
        height: 12rem;
    }

    .video-preview{width: 100%;}
    .two-pics{display: none;}
} 

// Mobile format
@media (max-width: 576px) { 
    .site-card-wrapper .grid-wrapper{
       // display: flex;
        //flex-direction: column;
        height: auto;
        grid-template-areas:
        "item1 item1"
        "item2 item3"
        "item4 item5"
        ;
    }
    .site-card-wrapper .item{
        height: 12rem;
    }
} 

// Mobile format min xs
@media (max-width: 480px) { 
    .site-card-wrapper .grid-wrapper{
       // display: flex;
        //flex-direction: column;
        height: auto;
        grid-template-areas:
        "item1 item1"
        "item2 item3"
        "item4 item5"
        ;
    }
    .site-card-wrapper .item{
        height: 12rem;
    }
} 
@primary-color: #2460B7;@link-color: #2460B7;@border-radius-base: 10px;@box-shadow-base: 0 9px 28px 8px rgba(0, 0, 0, 0.05);