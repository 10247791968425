.ant-switch {
    border: solid 2px var(--gray-100);
    .ant-switch-handle {
        top: 0;
    }
    .ant-switch-handle::before {
        background-color: var(--gray-100);
    }
}

.ant-switch-checked {
    border: solid 2px var(--blue-100);
    background-color: var(--blue-100);
    .ant-switch-handle {
        top: 0;
        left: calc(100% - 18px - 1px);
    }
    
    .ant-switch-handle::before {
        background-color: var(--white);
        color: white;
    }
}

.packages-form .ant-switch {
    border: solid 2px var(--gray-100);
    border-radius: 1.4rem;
    .ant-switch-handle {
        top: 0;
    }
    .ant-switch-handle::before {
        background-color: var(--gray-100);
    }
}

.packages-form .ant-switch.br-1 {
    border-radius: 1.4rem !important;
}

.packages-form .ant-switch-checked {
    border: solid 2px var(--red-50);
    background-color: var(--red-50);
    .ant-switch-handle {
        top: 0;
        left: calc(100% - 18px - 1px);
    }
    
    .ant-switch-handle::before {
        background-color: var(--white);
        color: white;
    }
}

// SMALL
.ant-switch-small.ant-switch-checked {
    .ant-switch-handle {
        left: calc(100% - 12px - 1px);
    }
}
@primary-color: #2460B7;@link-color: #2460B7;@border-radius-base: 10px;@box-shadow-base: 0 9px 28px 8px rgba(0, 0, 0, 0.05);