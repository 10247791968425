@import "responsive.less";

.byp-btn {
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border-radius: 0.4rem;
  margin: 0.5rem;
  padding: 0.25rem;
}

.byp-btn-sm {
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 0.4rem;
}

.byp-btn-blue-200,
.byp-btn-blue-200:hover {
  background-color: var(--blue-200);
  color: var(--full-white);
  font-size: small;
  font-weight: bold;
}

.byp-btn-blue-100,
.byp-btn-blue-100:hover {
  background-color: var(--blue-100);
  color: var(--full-white);
  font-size: small;
  font-weight: bold;
}

.byp-btn-blue-100-outline,
.byp-btn-back {
  background-color: transparent;
  border: solid 0.1rem var(--blue-100);
  color: var(--blue-100);
  font-weight: bold;
  font-size: small;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.byp-btn-gray-100-outline {
  background-color: var(--white);
  border: solid 0.1rem var(--full-gray);
  color: var(--full-gray);
  font-weight: bold;
  font-size: small;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.byp-btn-red-50 {
  background-color: var(--red-50) !important;
  border-color: var(--red-50) !important;
  color: var(--white);
  font-weight: 600;
}

.byp-btn-red-50 {
  background-color: var(--red-50) !important;
  border-color: var(--red-50) !important;
  color: var(--white);
  font-weight: 600;
  border-radius: 5px 5px 5px 5px !important;
}

.btn--text-responsive {
  font-size: 16px;
}

@media (max-width: 768px) {
  .btn--text-responsive {
    font-size: 12px;
  }
}

.byp-btn-red-50_forgot {
  //background-color: var(--red-50) !important;
  border-color: var(--red-50) !important;
  color: var(--white);
  font-weight: 600;
  display: flex;
  padding: 10px 25px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  height: 38px;
  border-radius: 5px;
  background: #ff395c;
}

.byp-btn-blog {
  padding: 6px 15px !important;
  border-radius: 6px !important;
  font-size: 12px;
}

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active .byp-btn-red-50 {
  // background-color: inherit;
  opacity: 0.8;
}

.byp-btn-red-50:focus,
.byp-btn-red-50:hover,
.byp-btn-red-50:focus:visited {
  background-color: var(--red-50);
  border-color: var(--red-50);
  color: var(--white);
}

.input_email {
  display: flex;
  height: 38px;
  padding: 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  border-radius: 10px;
  border: 1px solid #bfbfbf;

  color: #1a171e;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  opacity: 0.45;
}

.input_email::placeholder {
  color: #1a171e;
  opacity: 0.45;
}

.ant-btn:active .byp-btn-red-50_forgot {
  // background-color: inherit;
  opacity: 0.8;
}

.byp-btn-red-50_forgotfocus,
.byp-btn-red-50_forgot:hover,
.byp-btn-red-50_forgot:focus:visited {
  background-color: var(--red-50);
  border-color: var(--red-50);
  color: var(--white);
}

.focus-none.ant-btn:hover,
.focus-none.ant-btn:focus,
.focus-none.ant-btn:active {
  background-color: transparent;
}

.byp-br-4 {
  border-radius: 0.4rem !important;
}

.byp-btn-back {
  // background-color: transparent;
}

.byp-link,
.byp-link span {
  border: none;
  text-decoration: underline;
  color: var(--blue-100);
  font-weight: bold;
  background-color: transparent;
}

.byp-link:hover,
.byp-link span:hover {
  background-color: transparent;
  color: var(--blue-200);
}

.byp-btn-media-upload {
  border-radius: 10px;
  background: var(--blue-200);
  color: #fff;
  border: solid 0.1rem var(--full-gray);
  width: 100%;
  display: flex;
  min-height: 3rem;
  cursor: pointer;

  .btn-media-img {
    width: 30%;
  }

  .btn-media-text {
    width: 70%;
  }
}

.packages-form .byp-btn-media-upload {
  justify-content: center;
  align-items: center;
  background: var(--black);
}

.text-blue-first {
  background-color: var(--blue-100);
  color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  align-self: center;
  border: solid;
  border-color: var(--blue-200);
  border-width: 1px;
}

.text-gray-first {
  background-color: lightgray;
  color: slategray;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  align-self: center;
  border: solid;
  border-color: slategray;
  border-width: 1px;
}

.text-gray-second {
  background-color: lightgray;
  color: slategray;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  align-self: center;
  border: solid;
  border-color: slategray;
  border-width: 1px;
}

.text-blue-second {
  background-color: var(--blue-100);
  color: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  align-self: center;
  border: solid;
  border-color: var(--blue-200);
  border-width: 1px;
}

.text-black-50 {
  color: var(--black-50) !important;
}

.text-black {
  color: var(--black) !important;
}

.byp-btn-dark,
.byp-btn-dark .ant-space-item {
  background-color: var(--black);
  color: white;
  border: solid 1px var(--black);
}

.byp-btn-dark:focus,
.byp-btn-dark:active,
.byp-btn-dark:hover {
  background-color: var(--black);
  color: white;
  border: solid 1px var(--black);
}

.byp-btn-media-upload:hover {
  background: var(--blue-100);
  color: #fff;
  transition: background 0.5s ease;
}

.byp-dashboard-content button,
.byp-dashboard-footer button {
  border-radius: 0.4rem !important;
}

.btn-icon {
  padding: 0 0.3rem !important;
}

.byp-btn-delete {
  background: transparent;
  border: none;
  color: red;
}

.byp-btn-edit {
  background: transparent;
  border: none;
  color: blue;
}

.byp-btn-transparent {
  background: transparent;
  border: none;
}

.byp-dashboard-content .byp-btn-list-function {
  border-radius: 50% !important;
  height: 30px;
  width: 30px;
  font-size: 0.9rem;
  color: var(--full-white);
  background-color: #d4d4d4;
}

.byp-dashboard-content .byp-btn-list-function-more {
  border-radius: 50% !important;
  height: 30px;
  width: 30px;
  font-size: 0.9rem;
  color: var(--white);
}

.byp-btn-list-function-add {
  background-color: #f4f4f4;
  border: 1px solid #2460b7;
  color: #2460b7;
  font-weight: bold;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 6px;
  width: 131px;
  height: 32px;

  svg {
    display: block !important;
  }
}

.byp-btn-list-function-del {
  background-color: #f4f4f4;
  border: 1px solid #686868;
  color: #686868;
  font-weight: bold;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 6px;
  width: 131px;
  height: 32px;
  align-items: center;
  align-content: center;
  justify-content: center;

  svg {
    display: flex !important;
  }
}

.ant-input-search-button,
.byp-input-gray .ant-input-search-button {
  border: none;
  background-color: var(--gray-50);
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  height: 2.2rem;
}

// .ant-input-search-button,
.byp-input-gray .ant-input-affix-wrapper {
  border-top-left-radius: 0.3rem !important;
  border-bottom-left-radius: 0.3rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-color: var(--gray-50);
  border: none;
}

.byp-input-red-50 .ant-input-search-button {
  border: none;
  background-color: var(--red-50);
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  height: 2.2rem;
}

.byp-input-red-50 .ant-input-affix-wrapper {
  background: bottom;
  border-top-left-radius: 0.3rem !important;
  border-bottom-left-radius: 0.3rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: solid 1px var(--gray-50);
}

.byp-btn-category,
.byp-btn-category:active,
.byp-btn-category:focus,
.byp-btn-category:hover {
  border-radius: 0.3rem !important;
  border: solid 2px #000;
  color: #fff;
  font-weight: bold;
  background-color: #000;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.75rem;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
  }

  & > svg {
    fill: #fff;
    width: 0.9rem;
    height: 0.9rem;

    & > path {
      fill: #fff;
    }
  }

  & > div > svg {
    fill: #fff;
    width: 0.9rem;
    height: 0.9rem;

    & > path {
      fill: #fff;
    }
  }
}

.byp-btn-category.ant-btn-background-ghost {
  border-radius: 0.3rem !important;
  border: solid 1px #797979;
  color: #797979;
  font-weight: bold;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  font-size: 0.75rem;
  padding: 0.75rem;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
  }

  & > div > svg {
    fill: #797979;
    width: 0.9rem;
    height: 0.9rem;

    & > path {
      fill: #797979;
    }
  }

  &:hover {
    background-color: #fff;
    color: #3b3b3b;
    border-color: #3b3b3b;
    cursor: pointer;

    & > div > svg {
      fill: #3b3b3b;
      width: 0.9rem;
      height: 0.9rem;

      & > path {
        fill: #3b3b3b;
      }
    }
  }
}

.byp-btn-yellow,
.byp-btn-yellow:active,
.byp-btn-yellow:focus,
.byp-btn-yellow:hover {
  background-color: #ffcc00;
  color: var(--full-white);
  font-weight: bold;
}

.byp-btn-yellow-blue,
.byp-btn-yellow-blue:active,
.byp-btn-yellow-blue:focus,
.byp-btn-yellow-blue:hover {
  background-color: var(--yellow-100);
  color: #2d2f66;
  font-weight: bold;

  svg {
    display: block !important;
  }
}

.byp-btn-other {
  width: 110px !important;
  margin-left: 1.2rem;

  svg {
    display: block !important;
  }
}

.byp-btn-expanded,
.byp-btn-not-expanded {
  background-color: var(--white);
  font-size: small;
  color: var(--blue-100);
  border-radius: 0.4rem !important;
}

.byp-btn-not-expanded {
  .eye-opened {
    display: none;
  }

  .eye-closed {
    display: inline-block;
  }
}

.byp-btn-not-expanded:hover {
  .eye-closed {
    display: none;
  }

  .eye-opened {
    display: inline-block;
  }
}

.byp-btn-category-m {
  margin-left: 8px;
  margin-right: 8px;
}

.discount-status__Aproved {
  background-color: var(--blue-100);
  color: #fff;
}

.discount-status__Active {
  background-color: var(--blue-100);
  color: #fff;
}

.discount-status__Created {
  background-color: var(--blue-50);
  color: #fff;
}

.discount-status__Inactive {
  background-color: var(--red);
  color: #fff;
}

.discount-status__FINISHED {
  background-color: var(--full-gray);
  color: #000;
}

.byp-favorite-icon-button {
  border-radius: 0.32144rem !important;
  background: #fff;
  margin-left: 1rem;
}

.btns__programs {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  row-gap: 0.5rem !important;
  justify-content: space-between;
  width: inherit;
  flex-wrap: nowrap;
  overflow-x: auto;
  overscroll-behavior-inline: contain;
  padding-bottom: 1rem;
}

//**BIG HOME PROGRAM BUTTONS
.btns_bigHome {
  display: flex;
  padding: 4px 0px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;

  .byp-btn-category.ant-btn-background-ghost > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    font-size: 14px;
    font-weight: 600;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    height: 60px !important;
    align-self: stretch;
    border: none;
  }

  .byp-btn-category.ant-btn-background-ghost > div > svg {
    fill: #797979;
    width: 24px;
    height: 24px;
    margin-bottom: 4px;
  }

  .byp-btn-category.ant-btn-background-ghost {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    font-size: 14px;
    font-weight: 600;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    height: 60px !important;
    align-self: stretch;
    border: none;

    &:hover {
      border: solid 1px #797979;
    }
  }
}

.btns__programs::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}

.btns__programs::-webkit-scrollbar-thumb {
  background: #6a6a6a;
  max-height: 4px;
  border-radius: 6px;
}

.btns__programs::-webkit-scrollbar-track {
  background-color: #dcdcdc;
}

@media (max-width: 768px) {
  .btns__programs {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    row-gap: 0.5rem !important;
    justify-content: space-between;
    flex-wrap: nowrap;
    max-width: 100vw;
    overflow: scroll;
  }
}

.snaps-inline {
  scroll-snap-type: inline mandatory;
}

.snaps-inline > * {
  scroll-snap-align: start;
}

.content-program__left {
  padding-right: 1.75rem;
  margin-bottom: 1rem;
}

.content-program__right {
  padding-left: 1.75rem;
}

@media (max-width: 768px) {
  .content-program__left {
    padding-right: 0;
  }

  .content-program__right {
    padding-left: 0;
  }
}

.byp-btn-gray-200 {
  background-color: var(--gray-200);
  border: solid var(--gray-200) 2px;
  color: var(--full-white);
  font-weight: 600;
}

.byp-btn-gray-200:focus,
.byp-btn-gray-200:hover {
  background-color: var(--gray-200);
  color: var(--full-white);
}

.byp-btn-gray-200-outline {
  background-color: transparent;
  border: solid var(--gray-200) 1px;
  color: var(--gray-200);
  font-weight: 600;
}

.byp-btn-gray-200-outline:focus,
.byp-btn-gray-200-outline:hover {
  background-color: transparent;
}

.byp-btn-gray-300 {
  background-color: var(--gray-300);
  border: solid var(--gray-300) 2px;
  color: var(--full-white);
  font-weight: 600;
}

.byp-btn-gray-300:focus,
.byp-btn-gray-300:hover {
  background-color: var(--gray-300);
  color: var(--full-white);
}

.byp-btn-gray-300-outline {
  background-color: transparent;
  border: solid var(--gray-300) 1px;
  color: var(--gray-300);
  font-weight: 600;
}

.byp-btn-gray-300-outline:focus,
.byp-btn-gray-300-outline:hover {
  background-color: transparent;
}

.search-offers-results {
  .btn-filters {
    display: none;
  }

  @media (max-width: 768px) {
    .btn-filters {
      display: block;
      margin-bottom: 1rem;
      border-radius: 5px !important;
    }

    .btn-filters.active {
      border: 1.75px solid #1a171e;
      color: #1a171e;
      font-weight: 600;
      background-color: #fff !important;
    }

    .btn-filters:focus,
    .btn-filters:hover,
    .btn-filters:active {
      background-color: transparent;
      border: 1.75px solid #494251;
      color: #494251;
      // border: none;
    }
  }
}

.btn-clean-filters {
  color: rgba(121, 121, 121, 1);
  font-weight: 600;
  font-size: 11px;
  border-radius: 6px !important;
  padding: 4px 12px;
}
