@text-color-secondary-dark: #2D2F66;
@input-color-blue: #2460B7;

:root {
  --full-black: #000000;
  --black: #1A171E;
  --black-50: rgba(55, 55, 55, 1); // #373737
  --blue-50: #6495ED;
  --blue-100: #2460B7;
  --blue-200: #2D2F66;
  --full-gray: #D4D4D4;
  --full-white: #ffffff;
  --gray: #F4F4F4;
  --gray-50: #EEEEEE; // rgba(238, 238, 238, 1)
  --gray-100: #686868;
  --gray-200: #797979;
  --gray-300: #5D5D5D;
  --gray-400: #373737;
  --white: #F7F7F7;
  --yellow-100: #F2A706;
  --red: #c0233d;
  --red-50: #FF395C; // rgba(255, 57, 92, 1)
  --background: #f4f4f4;
  
}

