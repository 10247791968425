.google-diconect {
    border: 1px solid #797979;
    padding: 0.625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    margin: 1rem 0;
    & > *,
    & > * > * {
        margin: 0;
    }
    & > div > img {
        width: 2.5rem;
        height: auto;
    }
    & > div:first-child {
        display: flex;
        gap: 1rem;
        align-items: center;
        font-size: 0.75rem;
        font-weight: 500;
    }
    & > div:nth-child(2) {
        font-size: 0.75rem;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        & > p:first-child {
            font-weight: 700;
        }
    }
    & button,
    & button:hover,
    & button:focus {
        border: 1px solid #797979;
        border-radius: 6px;
        color: #797979;
        background-color: #F7F7F7;
    }
}