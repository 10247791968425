.account__google-text {
    font-size: 0.688rem;
    line-height: 1.375rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    & > span {
        text-decoration: underline;
    }
}

.align_text-with-icon {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.justyfy-center {
    display: flex;
    justify-content: center;
}

.quote__text-aprox {
    font-size: 0.7rem;
    font-weight: 300;
}

.quote-summary__titles {
    font-size: 2.188rem;
    font-weight: 600;
    color: #3B3B3B;
}

.quote-summary__subtitles {
    font-size: 1.125rem;
    font-weight: 300;
    color: #3B3B3B;
}

.quote-summary__subtitle-2 {
    font-size: 0.875rem;
    font-weight: 600;
    color: #1A171E;
}

.quote-summary__info {
    font-size: 0.875rem;
    font-weight: 400;
    color: #1A171E;
    & > span {
        font-weight: 600;
    }
}

.quote-summary__info-adviser {
    display: flex;
    align-items: center;
    & > p{
        font-size: 0.875rem;
        font-weight: 500;
        color: #3B3B3B;
        margin: 6px ;
    }
}

.quote-summary__info-reverse {
    font-size: 0.875rem;
    font-weight: 600;
    color: #1A171E;
    & > span {
        font-weight: 400;
    }
}

.opinion-title {
    font-size: 1.875rem;
    color: #373737;
}

.opinion-subtitle {
    font-size: 1.125rem;
    color: #373737;
    font-weight: 700;
}

.discount-text {
    & > p {
        font-size: 0.688rem;
        font-weight: 700;
        color: #2A2F38;
    }
}

.title-aplication {
    color: #3B3B3B;
    font-size: 2.188rem;
    font-weight: 600;
}

.subtitle-aplication {
    color: #000000;
    font-size: 1.25rem;
    font-weight: 600;
}

.label-aplication {
    font-size: 0.75rem;
    color: #000000;
    font-weight: 400;
}

.footer-text-aplication {
    color: #000000;
    font-size: 0.875rem;
    font-weight: 400;
    & > a {
        color: #000000;
        text-decoration: underline;
    }
}
.text-discount .ant-statistic-content-suffix,
.text-discount .ant-statistic-content-value-decimal,
.text-discount .ant-statistic-content-value-int,
.text-discount .ant-statistic-content-value,
.text-discount {
    color: #FF395C;
}

.text-align-center {
    text-align: center;
}