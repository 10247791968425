.btn__pink,
.btn__pink:hover,
.btn__pink:focus {
    border-radius: 5px !important;
    background-color: #FF395C !important;
    font-weight: 500;
    color: var(--white);
    border: #FF395C solid 1px;
    padding: 0 1rem;
}

.btn__pinkConfirm,
.btn__pinkConfirm:hover,
.btn__pinkConfirm:focus {
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    color: #5D5D5D;
    display: flex;
    gap: 1rem;
    border: 1px solid #5D5D5D;
    background: transparent;
    font-size: 0.75rem;
    padding: 0.5rem 1rem;

    & svg {
        width: 0.75rem;
    }

    width: 100%;
}

.btn__gray,
.btn__gray:hover,
.btn__gray:focus {
    border-radius: 6px !important;
    background-color: #5D5D5D !important;
    color: #fff;
    border: #5D5D5D solid 1px;
    padding: 0 1rem;
    line-height: 22px;
}

.btn-application,
.btn-application:hover,
.btn-application:focus {
    border-radius: 4px;
    color: #ffffff;
    display: flex;
    align-items: center;
    gap: 1rem;
    //border: 1px solid #5D5D5D;
    background: #FF395C;
    font-size: 0.75rem;
    padding: 12px;

    & svg {
        width: 0.75rem;
    }
}
