.form-item__textarea {
    & > textarea {
        border: 1px solid #D4D4D4;
        background-color: transparent;
    }
}

.form-item__input-number {
    border: none;
    background-color: transparent;
    & input {
        border: 1px solid #D4D4D4;
        background-color: transparent;
        border-radius: 10px;
    }
}