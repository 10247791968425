.card-paid-quote {
  background-color: #F4F4F4;
  // border: 1px solid var(--gray-100);
  border-radius: 16px;

  .carousel-item {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    height: 100%;
  }
}

.card-paid-quote .course-title {
  color: #373737;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 550;
}

.course-duration {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.program-label {
  position: absolute;
  //bottom: -20px;
  background-color: var(--full-white);
  padding: 0.35rem 0.75rem;
  border-radius: 0 12px 12px 0;
  font-size: 0.625rem;
  top: 1rem;
  left: 0;
}
@primary-color: #2460B7;@link-color: #2460B7;@border-radius-base: 10px;@box-shadow-base: 0 9px 28px 8px rgba(0, 0, 0, 0.05);