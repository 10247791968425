.offer-list-container {
  padding: 0 !important;
  padding-right: 25px !important;
}

.offer-list-container {
  max-height: 1000px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 25px;
}

@media (max-width: 768px) {
  .offer-list-container {
    max-height: none;
    padding: 0;
    overflow: hidden;

  }

  .offer-list-container.p-0 {
    padding: 0 !important;
  }
}

.offer-list-container::-webkit-scrollbar {
  background: transparent;
  width: 4px;

}

/* Track */
.offer-list-container::-webkit-scrollbar-track {
  background: transparent;

}

/* Handle */
.offer-list-container::-webkit-scrollbar-thumb {
  background: var(--blue-50);
  max-height: 2px;
  border-radius: 20px;
}

/* Handle on hover */
.offer-list-container::-webkit-scrollbar-thumb:hover {
  background: #585655;

}


.offer-list {
  background-color: #f4f4f4;
  padding-left: 10px;

  .ant-list-item-meta-title {
    font-size: 1em;
    color: #919191;
    margin-bottom: 0 !important;
  }

  .ant-list-item-meta-description {
    font-size: 1.4em;
    color: var(--gray-100);
    font-weight: 700;
  }
}

.fav-button {
  background-color: transparent !important;
  border: none !important;
  position: absolute !important;
  top: -1px;
  left: 12px;

  svg {
    color: var(--yellow-100);
  }
}

.fav-button2 {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  background: #F7F7F7;
  border-radius: 4px;
  display: grid;
  place-content: center;
}

.quote-list-item-card {
  .quote-campus__container {
    min-height: 12.5rem;
    padding: 1.25rem;
  }

  .quote-campus__btns {
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;

    .ant-btn[disabled] {
      background: #D4D4D4;
      color: var(--white);
    }
  }

  .quote-campus__btns>a {
    gap: 0.344rem;
  }

  .ant-list-item-meta-title {
    font-size: 1.4em;
    color: var(--black);
    margin-bottom: 0 !important;
  }

  .ant-statistic-content {
    color: var(--blue-200);
  }

  .ant-statistic-content-prefix,
  .ant-statistic-content-value {
    font-weight: bold;
  }

  .quote-campus-name {
    position: absolute;
    bottom: 146px;
    left: 15px;
    width: 50%;
    color: #fff;
  }
}

.card-quoter-image-header {
  .quote-campus-dates {
    font-size: 1em;

    background-color: var(--blue-100);
    color: var(--white);

    border-radius: .3rem;
    border: 1px solid #2D2F66;
    padding-left: 0.3rem;

    .quote-campus__status {
      border-left: 1px solid #2D2F66;
      margin-left: 0.3rem;
      padding: 0 0.3rem;
      font-weight: 700;
    }
  }

  .quote-campus-dates.active {
    background: #D4D4D4;
    color: #686868;
    border: 1px solid #686868;

    .quote-campus__status {
      border-left: 1px solid #686868;
    }
  }
}

.remember-text {
  color: #2460B7;
  font-size: 0.875rem;
  margin: 1rem;
  margin-bottom: 1.5rem;

  span {
    font-weight: 700;
  }
}

.quote-campus__modal {
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    margin: 0 1.5rem;
  }

  .ant-modal-header,
  .ant-modal-footer {
    border: none;
  }

  .ant-btn.ant-btn-default {
    border: 1px solid #2460B7;
    border-radius: 6.95652px;
    color: #2460B7;
    font-weight: bold;
    background-color: transparent;
    margin-bottom: 0.7rem;
  }

  .ant-btn.ant-btn-default:hover,
  .ant-btn.ant-btn-default:active,
  .ant-btn.ant-btn-default.active {
    background-color: #2460B7 !important;
    color: #ffffff !important;
  }

  .ant-btn.ant-btn-default:focus {
    color: #2460B7;
  }

  .ant-modal-title {
    font-weight: 700;
    color: #2D2F66;
    font-size: 0.75rem;
  }
}
@primary-color: #2460B7;@link-color: #2460B7;@border-radius-base: 10px;@box-shadow-base: 0 9px 28px 8px rgba(0, 0, 0, 0.05);