.blog {
    margin: 2.5rem 0;
    &__header {
        overflow: hidden;
        height: 33rem;
        display: grid;
        place-content: center;
        border-radius: 12px;
        position: relative;
        & > div:first-child {
            filter: brightness(50%);
            background-color: #FFFFFF;
            width: 100%;
            height: 18rem;
            display: flex;
            align-items: center;
            // background-color: #00000073;
        }
        & h1 {
            position: absolute;
            left: 2rem;
            bottom: 1rem;
            color: #FFFFFF;
            margin: 0;
        }
        @media (max-width: 768px) {
            height: 17rem; /* Altura ajustada para dispositivos móviles */
    
            & > div:first-child {
                height: auto; /* O ajusta según sea necesario para dispositivos móviles */
            }
        }
    }
    &__header-no-image {
        overflow: hidden;
        background-color: #FFFFFF;
        height: 18rem;
        width: 100%;
        border-radius: 12px;
    }

    &__container {
        padding-right: 1.5rem;
    }

    &__body {
        font-size: 1rem;
        margin: 0;
    }
    
    &__add-comment {
        background-color: #E9E9E9;
        text-align: center;
        padding: 0.5rem;
        border-radius: 5px;
        & > a {
            font-size: 0.75rem;
            color: #000;
        }
    }

    &__ads {
        padding-left: 1.5rem;
    }
}


.blog-ads {
    &__tags {
        display: flex;
        gap: 0.5rem;
        flex-wrap: wrap;
        & > span {
            border-radius: 6px;
            background-color: #EEEEEE;
            padding: 0.25rem 0.5rem;
        }
    }
    &__title-tag {
        color: #000000;
        font-size: 1.563rem;
    }
    &__ads {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        & > span {
            border-radius: 6px;
            background-color: #EEEEEE;
            padding: 0.25rem 0.5rem;
            color: #000000;
            font-size: 1rem;
        }
    }
}


.blog-card {
    border-radius: 20px;
    overflow: hidden;
    background-color: #FFFFFF;
    //box-shadow: -3px 5px 13px 0px #0000001F;

    &__header {
        height: 10rem;
        overflow: hidden;
        display: grid;
        place-content: center;
        position: relative;
        & > span {
            position: absolute;
            background-color: #FFFFFF;
            padding: 0.313rem 0.625rem;
            top: 1rem;
            left: 0;
            border-radius: 0 12px 12px 0;
        }
    }

    &__body {
        padding: 0.75rem;
        & > h4 {
            color: #373737;
            font-size: 1.125rem;
            line-height: 1.25rem;
            font-weight: 600;
            margin-bottom: 0.75rem;
        }
        & > p {
            font-size: 0.75rem;
            color: #373737;
            font-weight: 400;
        }
        & > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #373737;
            & > a {
                color: #373737;
                font-size: 0.625rem;
                display: inherit;
            }
        }
        .extract {
            height: 5rem;
            overflow: auto;
        }
    }
}

.blog-card-simple {
    border-radius: 20px;
    overflow: hidden;
    background-color: #FFFFFF;
    //box-shadow: -3px 5px 13px 0px #0000001F;
    position: relative;
    min-height: 17rem;
    .blog-title {
        // position: absolute;
        color: #373737;
        padding: 0.313rem 0.625rem;
        // top: 2rem;
        // left: 1rem;
        margin-top: 2px;
        font-size: 1.25rem;
        line-height: 1.26rem;
    }
    .blog-link {
        // position: absolute;
        // top: 2rem;
        // left: 1.625rem;
        // background-color: #373737;
        padding: 0.313rem 0.625rem;
        color: #373737;
        font-weight: 700;
        font-size: 0.656rem;
        border-radius: 10px;
        cursor: pointer;
        padding: .3rem 1rem;
        display: flex;
        align-items: center;
        width: fit-content;
    }
    
    .ant-image {
        width: 100%;
    }

    .blog-cover {
        height: 17rem;
        width: 100%;
        object-fit: cover;
    }

    @media (max-width: 768px) {
        .blog-title {
            transform: none;
            text-align: justify;
        }
    }
}

.big-home__blog-language .blog-info {
    // position: absolute;
    // top: 2rem;
    // left: 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}
.big-home__blog-language .blog-link {
    // background-color: #373737;
    color: #373737;
}
.big-home__blog-language .blog-title {
    color: #000;
}

.blog-comments {
    background-color: #FFFFFF;
    padding: 0.75rem;
    border-radius: 20px;
    margin-bottom: 1rem;
    & > p {
        margin: 0;
        font-size: 0.75rem;
    }

    &__user {
        display: flex;
        align-items: center;
        gap: 1rem;
        &-image {
            width: 3.375rem;
            aspect-ratio: 1;
            border-radius: 99999px;
            overflow: hidden;
            display: grid;
            place-content: center;
        }
        &-info {
            & > p {
                margin: 0;
                color: #373737;
                font-size: 0.75rem;
            }
            & > div {
                margin-top: 0.5rem;
                border: 1px solid #797979;
                padding: 0.35rem 0.75rem;
                font-size: 0.75rem;
                color: #373737;
                border-radius: 4px;
            }
        }
    }
}

.blog__footer {
    background-color: #EEE;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    // &-btn {
    //     font-size: 14px;
    //     font-weight: 600;
    //     color: #FFFFFF;
    //     background-color: #FF395C;
    //     border-color: #FF395C;
    //     display: flex;
    //     align-items: center;
    //     padding: 17.5px;
    //     gap: 10px;
    //     &:hover {
    //         background-color: #FF627E !important;
    //     }
    // }
}

.edit-blog__image {
    max-height: 18rem;
    overflow: hidden;
    display: grid;
    place-content: center;
}

.add-blocks__btn {
    background-color: transparent;
    text-align: center;
    border-radius: 5px;
    font-size: 0.75rem;
    color: #000;
    border: none;
    &:hover {
        background-color: #E9E9E9;
        border: none;
        color: #000;
    }
    box-shadow: none;
}

.dropdown__add-block {
    background-color: #E9E9E9;
    border-radius: 5px;
    display: grid;
    place-content: center;
    &:hover {
        outline: 1px solid #1A171E;
    }
}

.dropdown__add-block.visible {
    outline: 1px solid #1A171E;
}

.dropdown__block {
    border: 1px solid #000;
    & > li {
        border-bottom: 1px solid #D4D4D4;
    }
    & > li:last-child {
        border: none;
    }
}

.edit-blog__form-item {
    & > .ant-select-selector {
        border: none !important;
        border-radius: 4px !important;
    }
    & .ant-select-selection-item {
        color: #000000;
        font-size: 0.625rem;
        border-radius: 0 !important;
        & .anticon-close {
            color: #FFFFFF;
            background-color: #000;
            display: grid;
            place-content: center;
            border-radius: 9990px;
            width: 0.9rem;
            aspect-ratio: 1;
            font-size: 0.5rem;
        }
    }
    .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
        display: flex;
        align-items: center;
        &:last-child::before {
            content: 'Añadir más';
            font-size: 0.688rem;
            color: #5D5D5D;
        }
    }
}

.edit-blog__form-item.ant-select-open {
    .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
        display: flex;
        align-items: center;
        &:last-child::before {
            content: '';
        }
    }
}

.block__type {
    background-color: #000;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    padding: 0 0.25rem;
    gap: 0.25rem;
}

.block__group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.block__container {
    border: 1px solid #D4D4D4;
    min-height: 5rem;
    &-video,
    &-image {
        margin: 0.5rem;
        background-color: #FFFFFF;
        height: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        & input {
            border: none;
        }
        & label {
            cursor: pointer;
        }
    }
    &-image {
        min-height: 10rem;
        height: auto;
    }
}

.block__footer {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
}

.clomun__btn {
    background-color: #FFFFFF;
}

.block-add {
    background-color: #FFFFFF;
    aspect-ratio: 1;
}

.textarea-bg-gray .ant-input{
    background-color: var(--gray);
}

.ellipsis-nonexpandable .ant-typography-expand {
    display: none;
}

.youtube-w-full {
    width: inherit;
}

.fit-image {
    // height: auto !important;
    object-fit: cover;
}

.container_img-100 {
    & .ant-image {
        width: 100%;
    }
}

.BlockTextP {
    word-wrap: break-word;
}
.BlockText {
    word-wrap: break-word;
    text-align: left; /* Alineación predeterminada */
}

@media only screen and (max-width: 768px) {
    .BlockText {
        text-align: center; /* Centrar solo en dispositivos con un ancho máximo de 768px (típicamente dispositivos móviles) */
    }
}