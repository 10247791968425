.desktop-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

.form-container {
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.login-form {
  max-width: 100%;
}

.login-form-forgot {
  float: right;
}

.ant-col-rtl .login-form-forgot {
  float: left;
}

.login-form-button {
  display: flex;
  width: 537px;
  padding: 20px 25px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 24px;
  font-weight: 500;
  background-color: var(--red-50) !important;
  border: none;
}

.ant-btn-primary {
  background-color: #2D2F66;
  border-color: #2D2F66;
}

.img-logo-container {
  width: 300px;
  height: 70px;
}

.text-container {
  width: 100%;
  max-width: 537px;
  float: left;
  margin-bottom: 32px;

  h1 {
    font-size: 1.597rem;
    font-weight: 500;
  }
}

.form-items-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.input-transparent {
  background-color: transparent;

  .ant-input {
    background-color: transparent;
  }
}

@media (max-width: 768px) {
  .form-container {
    width: 100%;
    padding: 0 15px 0px 15px;
  }

  .img-container-login {
    display: none;
  }

  .google-bottom {
    width: 100%;
  }

  .login-form-button {
    width: 100%;
  }

  .login-form {
    min-width: 95%;
  }
}
@primary-color: #2460B7;@link-color: #2460B7;@border-radius-base: 10px;@box-shadow-base: 0 9px 28px 8px rgba(0, 0, 0, 0.05);