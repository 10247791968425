.google_translate_container {
  // max-width: 10rem;
  // min-width: 8rem;
  // min-height: 1.5rem;
  // background-color: aqua;
  // max-height: 4rem;
  // max-width: 9rem;
  min-width: 7.5rem;
}

.google_translate_container .skiptranslate {
  display: grid;
  align-items: flex-end;
  justify-content: flex-end;
}

.goog-te-combo {
  border-radius: .3rem;
  padding: 0.2rem;
  background: var(--full-white);

  margin: 4px 0px 4px 20px;
  width: 7rem;
  font-family: 'Poppins' !important;
}

.google_translate_container-quote {
  min-width: 7.5rem;
  height: 2rem;
  overflow: hidden;  
}

.google_translate_container-quote .goog-te-gadget {
  padding-left: 1rem;
}

// #google_translate_element .goog-te-combo {
//   height: 5rem;
// }

#google_translate_element .goog-te-combo,
#google_translate_element .goog-te-combo:focus-visible {
  border: none;
  color: #2D2F66;
  outline: none;
  background-color: transparent;
  font-weight: 700;
}

#google_translate_element .goog-te-combo:hover,
#google_translate_element .goog-te-combo:focus {
  border-bottom: 2px solid #2D2F66;
  border-radius: 0px;
}

#google_translate_element .goog-te-combo>option:focus-visible {
  color: #fff;
}

#google_translate_element .goog-te-combo {
  position: relative;
}

#google_translate_element {
  position: relative;
  margin-left: 1.5rem;
}

#google_translate_element .goog-te-combo {
  padding-left: 1.5rem;
}

.google_translate_container-quote::before {
  content: "";
  display: block;
  background-image: url("../images/translate.png");
  background-repeat: no-repeat;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 0.5rem;
}

.google_translate_container::before {
  content: "";
  display: block;
  background-image: url("../images/translate.png");
  background-repeat: no-repeat;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 1.5rem;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  #google_translate_element {
    position: relative;
    margin-left: 0.5rem;
  }
  
  #google_translate_element .goog-te-combo {
    padding-left: 0.8rem;
  }
}

body {
  top: 0 !important;
}

.skiptranslate:not(.goog-te-gadget) {
  display: none;
}
@primary-color: #2460B7;@link-color: #2460B7;@border-radius-base: 10px;@box-shadow-base: 0 9px 28px 8px rgba(0, 0, 0, 0.05);