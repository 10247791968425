.step-footer-style {
    background-color: transparent;
    border-top: none;
}

#action-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: -100px;
}

.RR {
    height: 30%;
    width: 100%;
    margin-top: -20%;
}

.Formulario {
    width: 100%;
}

.step-margin {
    margin: 18px 20%;
}


@media (max-width: 768px) {
    .img-container-register {
        display: none;
    }

    .register-title {
        /* poppins */
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        color: var(--black-50);
    }

    .step-margin {
        margin: 12px 5%;
    }

    .step-body {
        margin-bottom: 0;
      }

    .anticon-check-circle,
    .anticon, .anticon-menu {
        width: 15px;
    }

    p {
        font-size: 12px;
    }
}

@media (min-width: 600px) {
    .RR {
        height: 100%;
        margin-top: 0;
    }
}


@media (min-width: 768px) {
    .RR {
        height: 100%;
    }

    .step-footer-style {
        background-color: #F7F7F7;
        border-top: 1px solid #F7F7F7;
    }
}