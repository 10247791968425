
.quote-byp__btn,
.quote-byp__btn:hover,
.quote-byp__btn:focus {
    border: 1px solid #797979;
    background-color: #F4F4F4;
    color: #797979;
    font-weight: 600;
    font-size: 0.75rem;
}

.quote-byp__btn-add,
.quote-byp__btn-add:hover,
.quote-byp__btn-add:focus {
    border: 2px solid #5D5D5D;
    background-color: #F4F4F4;
    color: #5D5D5D;
    font-weight: 600;
    font-size: 0.75rem;
    border-radius: 6px !important;
}

.mr-05 {
    margin-right: 0.5rem;
}

.quote-byp__btn-info,
.quote-byp__btn-info:hover,
.quote-byp__btn-info:focus {
    border: none;
    color: #797979 !important;
    background-color: transparent !important;
}

.quote-byp__btn-info:hover {
    border: 1px solid #373737;
}

.quote-byp__btn-info.active {
    border: 1px solid #373737 !important;
    background-color: #373737 !important;
    color: #f4f4f4 !important;
}

.quote-byp__icon-card {
    padding: 1rem;
    max-width: 10rem;
    min-width: 10rem;
    min-height: 12rem;
    max-height: 12rem;
    text-align: center;
    // background: var(--gray-50);
    border-radius: 6px;

    svg {
        min-width: 40px;
        height: auto;
    }

    // .ant-typography {
    //     white-space: nowrap;
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    //     max-width: 8rem;
    // }
}

.quote-byp__icon-group-card {
    svg {
        min-width: 14px;
        height: auto;
    }
}

.quote-byp__icon-group-card .filled-icon {
    background: black;
    color: whitesmoke;
    border-radius: 4px;
    min-height: 26px;
    // max-width: 25px;
}

.quote-byp__icon-group-card .outline-icon {
    border: solid 1px black;
    border-radius: 4px;
    min-height: 26px;
    // max-width: 25px;
}

.quote-byp__btn-opinion {
    border: 1px solid #797979;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-weight: 600;
    color: #797979;
    background-color: transparent !important;

    & a {
        font-weight: 600;
        color: #797979;
    }
}

.quote-byp__btn-opinion:hover,
.quote-byp__btn-opinion:focus {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-weight: 600;
    color: #797979;
    border: 1px solid #373737 !important;
    background-color: #373737 !important;

    & a {
        font-weight: 600;
        color: #f4f4f4;
    }
}

.quote-byp__btn-opinion2 {
    color: black;
    background-color: white !important;
}

.quote-byp__btn-opinion2:hover,
.quote-byp__btn-opinion2:focus {
    color: white;
    background-color: black !important;
}

.quote-byp__btn-aplicar {
    border: 1px solid #FF395C;
    background-color: #FF395C;
    color: #FFFFFF;
    font-weight: 600;
}

.quote-byp__btn-aplicar:hover {
    border: 1px solid #FF395C;
    background-color: #fff;
    color: #FF395C;
}

.quote-byp__habilidades {
    border: none;
    background-color: #EBEBEB;
    color: #1A171E;
    font-weight: 300;
    font-size: 0.875rem;
    padding: 0.25rem 0.375rem;
    border-radius: 6px;
}

.flex-byp__group {
    display: flex;
}

.quote-byp__container p,
.quote-byp__container h1,
.quote-byp__container h3 {
    margin: 0;
}

.quote-byp__container h1 {
    color: #3B3B3B;
    font-size: 2.188rem;
    font-weight: 600;
}

.quote-byp__container p {
    font-size: 1.25rem;
    font-weight: 400;
    color: #1A171E;
}

.quote-byp__container p>span {
    font-weight: 600;
}

.quote-byp__container h3 {
    color: #3B3B3B;
    font-size: 1.375rem;
    font-weight: 500;
}

.quote-byp__mb {
    margin-bottom: 1.25rem !important;
}

.quote-byp__description {
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    color: #373737 !important;
}

.quote-byp__list {
    padding-left: 1.5rem;
    font-size: 0.875rem;
    font-weight: 400;
}

.social-byp__group {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.quote-byp__new_search {
    background-color: #EEEEEE;
}

.quote-byp__new_search .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.quote-byp__new_searchPack .ant-card-body {
    height: 188px
}

.quote-byp__school>p,
.quote-byp__school>span {
    font-size: 0.875rem;
    color: #1A171E;
    font-weight: 400;
}

.quote-byp__school>h4 {
    font-size: 1.563rem;
    font-weight: 600;
    color: #3B3B3B;
}

.quote-byp__new_search h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #373737;
}

.quote-byp__new_search button,
.quote-byp__new_search button:hover,
.quote-byp__new_search button:focus {
    background-color: #FFFFFF;
    border: 1px solid #797979;
    color: #797979;
    font-weight: 600;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
}

.quote-byp__new_search button svg {
    width: 1rem;
    height: 1rem;
}

.quote-byp__btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.footer-byp__card {
    border: 1px solid #373737;
}

.footer-byp__card img {
    width: 3.25rem;
    aspect-ratio: 1;
    border-radius: 9999px;
}

.quote-byp__btns button {
    border: 1px solid #5D5D5D;
    background-color: #F7F7F7;
    color: #5D5D5D;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.35rem;
}

.quote__btn-quote,
.quote__btn-quote:hover,
.quote__btn-quote:focus {
    border: 2px solid #5D5D5D;
    background-color: #F7F7F7;
    color: #5D5D5D;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px !important;
}

.cotizacion__title {
    font-size: 1.875rem;
    font-weight: 500;
    color: #373737;
}

.cotizacion__rate {
    font-size: 0.875rem;
    color: #5D5D5D;
    font-weight: 300;
}

.cotizacion__rate span {
    font-weight: 700;
}

.cotizacion__description {
    font-size: 0.9rem;
    color: #1A171E;
    font-weight: 400;
}

.cotizacion__name-campus {
    color: #2A2F38;
    font-size: 1.375rem;
    font-weight: 300;
}

.cotizacion__name-offer {
    color: #1A171E;
    font-size: 1.125rem;
    font-weight: 700;
}

.cotizacion__only-input {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.cotizacion__only-input > div {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #D4D4D4;
    padding: 0.375rem 1rem;
}

.cotizacion__only-input .ant-form-item {
    margin: 0;
}

// Cotización container
.cotizacion__start-end {
    display: flex;

     // Mobile queries
   @media (max-width: 768px) {
        & {
            flex-direction: column;
        }
    }

}

.cotizacion__start-end .ant-picker-input input {
    color: #797979;
}

.cotizacion__start-end .ant-form-item {
    margin: 0;
}

.cotizacion__start-end>div {
    border: 1px solid #D4D4D4;
    padding: 0.375rem 1rem;

    @media (max-width: 768px) {
        & {
            padding: 0.375rem 1rem .7rem;
        }
    }
}

.cotizacion__start-end>div:first-child .ant-form-item.ant-form-item-has-success {
    margin: 0;
}

// "Inicio de clases" container
.cotizacion__start-end>div:first-child {
    border-radius: 12px 0px 0px 12px;
    display: flex;
    flex-direction: column;
    // width: 80%;
    width: 50%;

      // Mobile queries
   @media (max-width: 768px) {
    & {
        width: 100%;
    }
}
}

// .cotizacion_duration .ant-form-item {
//     margin: 0;
// }

// .cotizacion_duration,
.cotizacion__start-end>div:first-child .ant-form-item-control-input-content {
    display: flex;
    flex-direction: column;
}

// "Duración del curso" container
.cotizacion__start-end>div:last-child {
    border-radius: 0px 12px 12px 0px;
    display: flex;
    flex-direction: column;
    // width: 20%;
    width: 50%;

     // Mobile queries
     @media (max-width: 768px) {
        & {
            width: 100%;
        }
    }
}

.cotizacion_duration,
p {
    margin: 0;
    font-size: 0.77281rem;
}

.cotizacion__total {
    display: flex;
    gap: 0.25rem;
}

.cotizacion_total,
.cotizacion_total .ant-statistic,
.cotizacion_total .ant-statistic-content,
.cotizacion_total .ant-statistic-content-value,
.cotizacion_total .ant-statistic-content-value-int,
.cotizacion_total .ant-statistic-content-value-decimal,
.cotizacion_total .ant-statistic-content-suffix {
    font-size: 1rem !important;
    color: #3B3B3B;
}

.cotizacion_total-discount,
.cotizacion_total-discount .ant-statistic,
.cotizacion_total-discount .ant-statistic-content,
.cotizacion_total-discount .ant-statistic-content-value,
.cotizacion_total-discount .ant-statistic-content-value-int,
.cotizacion_total-discount .ant-statistic-content-value-decimal,
.cotizacion_total-discount .ant-statistic-content-suffix {
    font-size: 0.75rem !important;
    color: #3B3B3B;
}

.cotizacion__total-currency,
.cotizacion__total-currency .ant-statistic,
.cotizacion__total-currency .ant-statistic-content,
.cotizacion__total-currency .ant-statistic-content-value,
.cotizacion__total-currency .ant-statistic-content-value-int,
.cotizacion__total-currency .ant-statistic-content-value-decimal,
.cotizacion__total-currency .ant-statistic-content-suffix {
    font-size: 1.25rem !important;
    color: #3B3B3B;
}


.cotizacion_btn-add {
    border: none;
    background-color: #ABD8AE;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
}

.cotizacion_btn-add .icon-mas {
    font-size: 1rem;
}

.cotizacion_btn-add:hover {
    border: none;
    background-color: #ABD8AE;
    color: #1A171E;
    width: 100%;
}

.cotizacion_btn-add:focus {
    border: none;
    background-color: #ABD8AE;
    color: #1A171E;
    width: 100%;
}

.cotizacion_btn-deleted {
    border: none;
    background-color: #EEEEEE;
    color: #1A171E;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
}

.cotizacion_btn-deleted svg {
    color: #E42A36;
    width: 1rem;
    height: 1rem;
}

.cotizacion_btn-deleted:hover {
    border: none;
    background-color: #EEEEEE;
    color: #1A171E;
    width: 100%;
}

.cotizacion_btn-deleted:focus {
    border: none;
    background-color: #EEEEEE;
    color: #1A171E;
    width: 100%;
}

.quote-apply_now {
    width: 100%;
    background-color: #FF395C;
    color: #fff;
    border: none;
    padding: 0 2rem;
    border-radius: 6px !important;
}

.quote-apply_now:hover,
.quote-apply_now:focus {
    width: 100%;
    background-color: #FF627E !important;
    color: #fff;
    border: none;
    padding: 0 2rem;
    border-radius: 6px !important;
}

.quote-byp__btn-add-top {
    border: 1px solid #E42A36;
    color: #E42A36;
    background-color: transparent;
    font-weight: 600;
}

@media (max-width: 568px) {
    .quotation__header-text {
        // color: green;
        margin-left: 5px;
    }
}

.quote-byp__btn-add-top:hover,
.quote-byp__btn-add-top:focus {
    border: 1px solid #FF627E;
    color: #FF627E;
    background-color: transparent !important;
    font-weight: 600;
}

.cotizacion__total .ant-statistic,
.cotizacion__total {
    font-size: 1.625rem;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.cotizacion-currency {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    & span {
        font-size: 0.75rem;
        font-weight: 300;
    }
}

.date_lodging__title {
    font-size: 1rem;
    color: #1A171E;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.footer-byp__card h4 {
    color: #373737;
    font-size: 1rem;
    font-weight: 400;
}

.footer-byp__like {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.footer-byp__card h5 {
    font-size: 1.375rem;
    color: #373737;
    font-weight: 700;
}

.footer-byp__card p {
    font-size: 1rem;
    font-weight: 400;
}

.footer-byp__card span {
    font-size: 1rem;
    text-decoration: underline;
    color: #000;
    font-weight: 500;
    cursor: pointer;
}

.travel__footer {
    display: flex;
    gap: 0.5rem;
    color: #FFFFFF;
    padding: 3.5rem 1.75rem 1.375rem;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    border: 2px solid #373737;
}

.travel__footer h4 {
    color: #FFFFFF;
    font-size: 2rem;
    font-weight: 600;
}

.travel__footer>div>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;

    &>p {
        color: #FFFFFF;
    }
}

.travel__footer>div>div span {
    height: 0.5rem;
    width: 1rem;
}

.opiniones__title {
    color: #373737;
    font-size: 1.375rem;
    margin: 0;
    font-weight: 700;

    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.opiniones__description {
    color: #797979;
    font-size: 1.125rem;
    font-weight: 400;
    margin: 0;
}

.radio_btn-quote .ant-radio-checked .ant-radio-inner,
.radio_btn-quote .ant-radio-checked:focus .ant-radio-inner {
    border-color: #FF395C;
}

.radio_btn-quote .ant-radio-inner::after {
    background-color: #FF395C;
}

.radio_btn-quote .ant-radio-disabled .ant-radio-inner::after {
    background-color: #686868;
}

.image-quoter .quote-gallery {
    min-height: 25rem;
}

.image-quoter .site-card-wrapper,
.image-quoter .site-card-wrapper * {
    height: 100%;
}

.image-quoter .site-card-wrapper {
    padding-left: 0;
    padding-right: 0;
}

.quoter-course__publicity {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 67rem;

    &>img {
        max-height: 67rem;
    }
}

.info-quote__container {
    & * {
        margin: 0;
    }

    & h2 {
        font-size: 2.188rem;
        font-weight: 600;
        color: #3B3B3B;
    }

    &>p {
        font-size: 0.875rem;
        font-weight: 400;
        color: #1A171E;
    }

    & .ant-divider {
        margin: 1.25rem 0;
    }

    & h3 {
        font-size: 1.125rem;
        color: #3B3B3B;
        font-weight: 300;
    }

    & .ant-col span {
        color: #1A171E;
        font-size: 0.875rem;
    }

    & .ant-col p,
    .info-quote__acomodation {
        color: #1A171E;
        font-size: 0.875rem;
        font-weight: 600;
    }
}

.info-quote__terminos {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &-span {
        color: #FF395C;
        font-size: 0.8rem;
        font-weight: 400;
        text-decoration: underline;
    }

    .ant-radio-inner::after {
        background-color: #FF395C;
    }

    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus+.ant-radio-inner {
        border-color: #FF395C;
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: #FF395C;
    }
}

.info-quote_phone-container {
    background-color: var(--full-white);
    padding: .6rem 1rem;
    border-radius: .5rem;
}

.btn__paypal {
    border: 1px solid #27346A;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    background-color: #F7F7F7;
    padding: 1.25rem 0;
    color: #27346a;

    &:hover,
    &:focus {
        background-color: #F7F7F7;
    }
}

.title {
    font-weight: 800;
    margin: 0;
}

.quote_paypal {
    box-shadow: -3px 5px 13px 0px #0000001F;
    border: 1px solid #D4D4D4;

    & * {
        margin: 0;
    }

    & h3 {
        display: flex;
        white-space: break-spaces;
        justify-content: center;
        flex-wrap: wrap;
        font-size: 1.33rem;
        font-weight: 500;
        margin-bottom: 1.5rem;
        line-height: normal;
    }

    @media (min-width: 1366px) {
        & h3 {
            font-size: 1.5rem;
        }
    }

    &__data {
        margin-bottom: 1.5rem;

        &-info {
            &>p:first-child {
                color: #3B3B3B;
                font-weight: 700;
                font-size: 1rem;
            }

            &>p:last-child {
                color: #3B3B3B;
                font-weight: 400;
                font-size: 0.7rem;
            }
        }

        &-price {
            &>p {
                font-size: 2rem;
                color: #3B3B3B;
                font-weight: 700;
            }
        }
    }

    .title {
        font-weight: 800;
        margin: 0;
    }

    .subtitle {
        font-weight: 700;
        font-size: 17px;
    }

    // .description {
        
    // }

    .line-through {
        text-decoration: line-through;
        text-decoration-thickness: 2px;
        font-weight: 800;
        margin: 0;
    }

    .quote_paypal__mobile-card {
        display: none;
    }

    @media (max-width: 768px) {
        .quote_paypal__desktop-card {
            display: none;
        }

        .quote_paypal__mobile-card {
            display: block;
        }
    }
}

.quote {
    &__overview-image {
        width: 100%;
        height: 6.25rem;
        border-radius: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        margin-bottom: 1.25rem;

        &>img {
            width: 100%;
        }
    }

    &__overview-info {
        &>h2 {
            font-size: 1.25rem;
            color: #3B3B3B;
            font-weight: 600;
        }

        &>p {
            font-size: 0.938rem;
            color: #1A171E;

            &>span {
                font-weight: 500;
            }
        }
    }

    &__overview-rate {
        background-color: #E5E5E5;
        width: 2.75rem;
        aspect-ratio: 1/1;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #373737;
        font-size: 1rem;
        font-weight: 700;
    }

    &__overview-opinions {
        font-size: 0.75rem;
        font-weight: 700;
        color: #373737;
        display: flex;
        align-items: center;
        gap: 0.25rem;
    }

    &__overview-collapse {

        & .ant-collapse-content.ant-collapse-content-active,
        & .ant-collapse-content-box,
        & .ant-collapse-header,
        & .ant-collapse-item.ant-collapse-item-active {
            border: none !important;
        }

        & .ant-collapse-header {
            background-color: #F9F9F9 !important;
        }

        & .ant-table-tbody>tr>td,
        .ant-table-thead>tr>th {
            padding: 0.75rem;
            border-bottom: 1px solid #DFDFDF;
        }
    }

    &-overview__totals {
        &>p:first-child {
            font-size: 1rem;
            font-weight: 700;
            color: #3B3B3B;
            margin: 0;
        }

        &>p:last-child {
            font-size: 1rem;
            color: #3B3B3B;
            margin: 0;
        }
    }

    &-overview__prices {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        font-size: 1.25rem;
        color: #3B3B3B;
        font-weight: 500;

        & .ant-statistic-content-prefix,
        & .ant-statistic-content-value-int,
        & .ant-statistic-content-value-decimal,
        & .ant-statistic-content-suffix {
            font-size: 1.25rem;
            color: #3B3B3B;
            font-weight: 500;
        }
    }

    &-apply__btn {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        background-color: transparent;
        border: 1px solid #797979;
        color: #797979;
        border-radius: 6px !important;
        padding: 0.25rem 0.5rem;
    }
}

.quote-overview__prices.price_1 {
    font-size: 1.5625rem;
    font-weight: 700;

    & .ant-statistic-content-prefix,
    & .ant-statistic-content-value-int,
    & .ant-statistic-content-value-decimal,
    & .ant-statistic-content-suffix {
        font-size: 1.5625rem;
        font-weight: 700;
    }
}

.quote-overview__prices.price_2 {

    & .ant-statistic-content-prefix,
    & .ant-statistic-content-value-int,
    & .ant-statistic-content-value-decimal,
    & .ant-statistic-content-suffix {
        font-size: 1rem;
    }
}

.quote__border:not(.ant-select),
.quote__border .ant-select-selector,
.quote__border .ant-picker.ant-picker-disabled {
    border: 1px solid #797979;
    border-color: #797979 !important;
    border-radius: 2px !important;
}

.confirmation-quote {
    margin: 3rem;

    &__container {
        padding: 1rem 1.375rem;
        padding-bottom: 6rem;
        background-color: #FFFFFF;
    }

    &__image {
        height: 7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 6px;
    }

    &__header {
        & p {
            font-size: 0.75rem;
            font-weight: 600;
        }
    }

    &__info {
        & p {
            font-size: 0.75rem;
            font-weight: 400;

            &>span {
                color: #FF395C;
            }
        }
    }

    &__accomodation {
        border-radius: 6px;
        background-color: #F8F8F8;
        padding: 0.5rem;
        height: 100%;

        &>p:first-child {
            font-size: 1rem;
            color: #3B3B3B;
            font-weight: 300;
        }

        &>.type {
            font-size: 0.95rem;
            color: #1A171E;
            font-weight: 700;
        }
    }

    &__date {
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 0.5rem;

        &>p:first-child {
            font-size: 0.75rem;
            color: #1A171E;
        }

        &>p:last-child {
            font-size: 0.9rem;
            color: #1A171E;
            font-weight: 600 !important;
        }
    }

    &__notice {
        border-radius: 6px;
        background-color: #F8F8F8;
        padding: 0.5rem;
        height: 100%;

        &>p:first-child {
            font-size: 1rem;
            color: #3B3B3B;
        }

        &>p:last-child {
            font-size: 0.75rem;
            background-color: #fff;
            padding: 0.25rem;
        }
    }

    &__data {
        &>h1 {
            color: #3B3B3B;
            font-size: 1.5rem;
            font-weight: 600;
            margin: 0;
        }

        &>.school {
            margin: 0;
            font-size: 1rem;
            font-weight: 400;
            color: #1A171E;

            &>span {
                font-weight: 500;
            }
        }
    }

    &__duration {
        font-weight: 500;
        font-size: 0.75rem;

        &>span {
            color: #FF395C;
        }
    }
}

.confirmation__dates {
    width: 100%;

    &>div {
        width: 50%;
    }
}

.bg-table-confirmation .ant-table-tbody {
    background-color: #F8F8F8;
}

.price__confirmation {
    font-size: 1rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    color: #3B3B3B;
    font-weight: 500;
}

.price__confirmation .ant-statistic-content-prefix,
.price__confirmation .ant-statistic-content-value-int,
.price__confirmation .ant-statistic-content-value-decimal,
.price__confirmation .ant-statistic-content-suffix {
    font-size: 1rem;
    color: #3B3B3B;
    font-weight: 500;
}

.discount__confirmation {
    font-size: 0.75rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    color: #3B3B3B;
    font-weight: 500;
    text-decoration: line-through;
}

.discount__confirmation .ant-statistic-content-prefix,
.discount__confirmation .ant-statistic-content-value-int,
.discount__confirmation .ant-statistic-content-value-decimal,
.discount__confirmation .ant-statistic-content-suffix {
    font-size: 0.75rem;
    color: #3B3B3B;
    font-weight: 500;
    text-decoration: line-through;
}


.button-whats {
    width: 200px;
    height: auto;
    border-radius: 100px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    position: fixed;
    bottom: 5vh;
    right: 2vh;
    z-index: 1000;
}

@media (max-width: 768px) {

    .button-whats {
        position: fixed;
        bottom: 10px;
        right: 10px;
        left: auto;
    }
}

.errors-dont {
    & .ant-form-item-explain-error {
        display: none;
    }

    & .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector,
    & .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
    .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover,
    & .ant-picker-status-error.ant-picker,
    .ant-picker-status-error.ant-picker:not(.ant-picker-disabled):hover {
        border-color: #d9d9d9 !important;
    }

    & .ant-input-number-group-wrapper-status-error .ant-input-number-group-addon {
        border-color: #d9d9d9 !important;
        color: #000;
    }
}

.info-quote_list {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
}

.byp-ant-radio {
    .ant-radio-checked .ant-radio-inner {
        border-color: var(--red-50);
    }

    .ant-radio-inner::after {
        background-color: var(--red-50);
    }

    .ant-radio-wrapper {
        // font
        font-weight: normal;
    }
}

.intake-byp__intake {
    & .ant-select-selector {
        border: 1px solid #797979;
    }
}

.custom-dflex {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    white-space: nowrap;
  }
  
  .custom-dflex p,
  .custom-dflex span {
    margin: 0; 
    font-size: 16px; 
  }
  
  @media (max-width: 1485px) {
    .info-quote__container .custom-dflex span,
    .info-quote__container .custom-dflex p {
      font-size: 12px !important;
    }
  }
  
  @media (max-width: 1320px) {
    .info-quote__container .custom-dflex {
      flex-direction: column; 
      align-items: flex-start; 
    }
  
    .info-quote__container .custom-dflex span,
    .info-quote__container .custom-dflex p {
      font-size: 16px !important; 
    }
  }
  
  @media (max-width: 1170px) {
    .info-quote__container .custom-dflex {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .info-quote__container .custom-dflex span,
    .info-quote__container .custom-dflex p {
      font-size: 14px !important;
    }
  }
  
  @media (max-width: 768px) {
    .info-quote__container .custom-dflex span,
    .info-quote__container .custom-dflex p {
      font-size: 16px !important; 
    }
  }