.select-blue-100 .ant-select-selector .ant-select-selection-item {
  color: var(--blue-100) !important;
  font-weight: bold;
}

.select-blue-200 .ant-select-selector .ant-select-selection-item {
  color: var(--blue-200) !important;
  font-weight: bold;
}

.select-danger .ant-select-selector .ant-select-selection-item {
  color: var(--red) !important;
  font-weight: bold;
}

.select-yellow-100 .ant-select-selector .ant-select-selection-item {
  color: var(--yellow-100) !important;
  font-weight: bold;
}

.multi-select .ant-select-selector {
  border-radius: 0px 6px 6px 0px !important;
  height: 37px !important;
}

.ant-select-selection-item{
  align-items: center !important;
  justify-items: center !important;
  display: 'flex' !important;
  padding-right: 10px !important;
}