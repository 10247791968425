.package__semanas {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: #DFDFDF;
    & > .weeks {
        padding: 0 0.5rem;
        font-size: 0.75rem;
    }
    & .ant-select-selector {
        background-color: #fff !important;
        border-radius: 0 !important;
    }
}