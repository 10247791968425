.byp-rate-sm {
  font-size: 15px;

  @media (max-width: 800px) {

    .anticon,
    .anticon-menu {
      width: 15px;
      font-size: 25px;
    }
  }
}

.byp-red-rate .ant-rate-star {
  color: var(--red-50);
}

.byp-red-rate .ant-rate-star-zero .ant-rate-star-second,
.byp-red-rate .ant-rate-star-zero .ant-rate-star-first {
  color: var(--background);

  path {
    stroke: var(--red-50);
    stroke-width: 68px;
  }
}

.byp-red-rate .ant-rate-star-half .ant-rate-star-second {
  color: var(--background);

  path {
    stroke: var(--red-50);
    stroke-width: 68px;
  }
}

// Gray
.byp-gray-rate .ant-rate-star {
  color: var(--gray-100);
}

.byp-gray-rate .ant-rate-star-zero .ant-rate-star-second,
.byp-gray-rate .ant-rate-star-zero .ant-rate-star-first {
  color: var(--background);

  path {
    stroke: var(--gray-100);
    stroke-width: 68px;
  }
}

.byp-gray-rate .ant-rate-star-half .ant-rate-star-second {
  color: var(--background);

  path {
    stroke: var(--gray-100);
    stroke-width: 68px;
  }
}

.edit_page-country .ant-rate-star:not(:last-child) {
  margin-right: 0.15rem !important;
}

.edit_page-country .ant-rate-star svg {
  width: 0.9rem;
  height: 0.9rem;
}
@primary-color: #2460B7;@link-color: #2460B7;@border-radius-base: 10px;@box-shadow-base: 0 9px 28px 8px rgba(0, 0, 0, 0.05);