.publicity {
    width: 100%;
    padding: 0 2rem;

    &__section {
        display: flex;
        flex-direction: column;
        gap: 0.375rem;
        & > h4 {
            font-size: 1.5rem;
            color: #1A171E;
            margin-bottom: 2rem;
            font-weight: 500;
        }
        & > h5 {
            color: #1A171E;
            font-size: 1rem;
            font-weight: 500;
            margin: 0;
        }
        & > h6 {
            color: #1A171E;
            font-size: 1.125rem;
            font-weight: 500;
            margin: 0;
        }
        & > span {
            color: #797979;
            font-size: 0.625rem;
            font-weight: 400;
            display: block;
            &:first-child {
                font-style: italic;
            }
        }
    }

    &-image__image {
        height: 6.25rem;
        overflow: hidden;
        border-radius: 6px;
        display: flex;
        align-items: center;
        border: 1px solid #D9D9D9;
        padding: 1px;
        background-color: #FFFFFF;
    }
    &-image__img {
        padding: 5px;
        border: 1px solid #686868;
        border-radius: 4px;
    }
    &-image__img-upload {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
        & .upload__image-container {
            display: flex;
            align-items: center ;
            width: 100%;
            cursor: pointer;
            & > p {
                margin: 0;
                flex: 1;
            }
            & > input {
                flex: 4;
                cursor: pointer;
            }
        }
        .remove__image {
            background-color: #D4D4D4;
            width: 1.5rem;
            height: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 9999px;
            border: 1px solid #686868;
            cursor: pointer;
        }
    }
    &-image__img-inputs {
        margin: 0.75rem 0 0 !important;
        border: 1px solid #D4D4D4;
        border-radius: 12px;
        padding: 0.75rem;
    }

    .publicity-image__inputs {
        display: flex;
        & > label {
            flex: 1;
        }
        & .ant-form-item {
            flex: 4;
        }
    }
    &-info__image {
        height: 15rem;
        border-radius: 12px;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
        grid-column: 1/3;

        & > img {
            width: 100%;
            height: auto;
        }
    }
    &-info__image-horizontal {
        height: 7rem;
        border-radius: 12px;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
        grid-column: 1/3;

        & > img {
            width: 100%;
            height: auto;
        }
    }
    &-info__info {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        & > p {
            color: #FFFFFF;
            margin: 0;
        }
    }
    &-image__url {
        flex: 4;
        padding: 1px 0.5rem;
        border: 1px solid #D9D9D9;
        border-radius: 2px;
        overflow: hidden;
    }
    &__time,
    &__date {
        border: none;
        background-color: #FFFFFF;
        width: 100%;
    }
    &-image__link {
        border: 1px solid #D9D9D9;
        border-radius: 2px;
        background-color: transparent;
    }
    &__submit {
        background-color: #FF395C;
        color: #FFFFFF;
        border-color: #FF395C;
        &:hover {
            background-color: #FF395C;
            border-color: #FF395C;
            color: #FFFFFF;
        }
    }
    &__grid {
        display: grid;
        grid-template-columns: 3fr 1fr;
        gap: 0.5rem;
    }
    &-info__image-vertical {
        overflow: hidden;
        display: flex;
        justify-content: center;
        height: 100%;
        height: 37.5rem;
        border-radius: 12px;
    }
}

.container__time {
    margin-top: 0.75rem;
    padding: 0.75rem;
    border: 1px solid #686868;
    border-radius: 4px;
    background-color: transparent;
}

.h-input {
    height: 2rem;
}